import React, {useEffect, useState} from 'react';
import Preloader from '../component/preloader'
import Footer from '../../component/footer'
import Header from '../component/header'
import Sidebar from './sidebar'
import axios from 'axios'
import { Alerts } from './notify';

import { BrowserRouter as Router, useLocation} from 'react-router-dom'
import { useDispatch } from 'react-redux';
import ProtectedView from '../ProtectedStaffView';
import { staffCode, Token, config,  ServerUrl, reset_password } from '../../component/include';
const Template = (props) =>
{

    let location  = useLocation()

    const dispatch = useDispatch()
    const [user, setUser] = useState({
		userPassword:'',
        loading:false
    })
    const [errors, setErrors] = useState({});
    const handleChange = event =>{		
        let {name, value} = event.target;	
       setUser({...user, [name] : value });
    }    

    const fetchStaff = async ()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetch_controller/tbl_staff'
        const {data} = await axios.post(url, fd, config)
        dispatch({
            type:'SET_RECORDS',
            name:'staff',
            data:data
        })
    }


    const submit=()=>{
       
		if(!user.userPassword){
            setErrors({errors, userPassword:'Please enter your password'})
        }else{

             setUser({...user, loading:true})
        const fd = new FormData();
       
		fd.append('jwt', Token)
        
		fd.append('userCode', staffCode)
		fd.append('userPassword', user.userPassword)
           let url = ServerUrl+'/update_controller/tbl_unlock_teacher_screen';
		axios.post(url, fd, config)
		.then(response =>{
	    if (response.data.type ==='success'){
            localStorage.removeItem('tlcksr')
            document.getElementById('closeLockscreen').click()
			
			}else{
                setErrors({errors, userPassword:response.data.message})
               
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
            window.location.href='/'
			 
		}).finally(()=>{
            setUser({...user, loading:false})
        })     
} 

    }



    const fetchSession = async ()=>{

        var sql ="Select  startYear, ID from tbl_school_session  order by startYear DESC"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        const {data} = await axios.post(url, fd, config)
            dispatch({
                type:'SET_RECORDS',
                name:'schoolSession',
                data:data
            })
        }

        const fetchStudents = async ()=>{
            const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_students'
            const {data} = await axios.post(url, fd, config)
            dispatch({
                type:'SET_RECORDS',
                name:'students',
                data:data
            })
        }

        const fetchSchoolInfo = async ()=>{

            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl+'/fetch_controller/tbl_basic_details'
                const {data} = await axios.post(url, fd, config)
                dispatch({
                    type:'SET_RECORDS',
                    name:'schoolInfo',
                    data:data
                })
        }

        const fetchClass = async ()=>{
            const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchField/tbl_school_class/schoolClass/ID/1'
            const {data} = await axios.post(url, fd, config)
            dispatch({
                type:'SET_RECORDS',
                name:'schoolClass',
                data:data
            })
        }
        
        const fetchCountry = async ()=>{
            const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_countries'
            const {data} = await axios.post(url, fd, config)
            dispatch({
                type:'SET_RECORDS',
                name:'countries',
                data:data
            })
        }


        useEffect(()=>{
          if(location.pathname !=='/staff/change_password' &&  reset_password ==='Yes'){
                window.open('/staff/change_password', '_self')
            } 
        }, [location.pathname])


    useEffect(()=>{
        fetchStaff();
        fetchSession()
        fetchStudents();
        fetchSchoolInfo()
        fetchClass()
        fetchCountry()
    }, [])
    return ( <Router>
        {/* <!-- Pre-loader start --> */}
        <Preloader />
        {/* <!-- Pre-loader end --> */}


        <div id="pcoded" className="pcoded">
    <div className="pcoded-overlay-box"></div>

    <div className="pcoded-container navbar-wrapper">

       {/* Header Component Here */}

       <Header />
       <a href="#!" data-toggle="modal" id='openlockscreenModal' data-target='#lockscreen' ></a>
  <div className="modal fade " data-backdrop="static" data-keyboard="false" id='lockscreen' tabIndex="-1" role="dialog">
        <div className="modal-dialog " role="document">
            <div className="modal-content">
               
                <div className="modal-body">
                <div className="row m-b-20">
			<div className="col-md-12">
				<h3 className="text-center"><i className="feather icon-lock text-primary f-60 p-t-15 p-b-20 d-block"></i></h3>
			</div>
</div>
			<div className="form-group form-primary">
        <button type="button" className="close" id='closeLockscreen' data-dismiss="modal" aria-label="Close"></button>
				<input type="password"  name="userPassword"  value={user.userPassword} placeholder="Enter your password to unlock" onChange={handleChange} className={errors.userPassword  ? 'form-control form-control-danger' : 'form-control'} /> 
				
				
			</div><span className=" form-bar error">{ errors.userPassword}</span>
			<div className="row">
				<div className="col-md-12">
				{user.loading===false?	<button type="button" onClick={submit}  className="btn btn-primary btn-md btn-block "><i className="icofont icofont-lock"></i> UnLock Screen </button>:<button type="button" disabled='true' className="btn btn-warning btn-md "><i className="icofont icofont-unlock"></i> Please wait... </button>}
				</div>
			</div>
                </div>
            </div>
        </div>
    </div>
{/* The  header End here */}


 {/* 
 <!-- Sidebar inner chat end--> */}
 <div className="pcoded-main-container">
     <div className="pcoded-wrapper">

     
        {/* Navbar start here */}

        <Sidebar menu = {props.menu}
        submenu= {props.submenu} /> 

{/* Navbar end here */}

    <div className="pcoded-content">
        <div className="pcoded-inner-content">

            {/* <!-- Main-body start --> */}
           <ProtectedView />

            
            {/* Footer Component Here */}
                <Footer />

{/* The Main Body close here */}
            </div>
            </div> 
           {/* The Main Body close here */}            
           </div></div>

            


</div>
            </div> 
        
        
            
    </Router>)
}
export default React.memo(Template)