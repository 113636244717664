import React, {useState, useEffect} from 'react'
import {Alerts} from '../component/notify'
import {ServerUrl, ImagesUrl, config } from '../component/include'
import axios from 'axios'
import Preloader from '../component/preloader'
import { LoadEffect } from '../component/loader'

 const RecoverPassword =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [user, setUser] = useState({
        password:'',
        schoolInfo:[],
        confirmPassword:'',
        readyLogin:false
    })

    const [errors, setErrors] = useState({});

    const handleChange = event =>{		
        let {name, value} = event.target;	
        setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }



    const fetchSchoolInfo = async ()=>{
        let sql = 'SELECT banner FROM tbl_basic_details';

        const fd = new FormData();
        fd.append('sql', sql)
        let url = ServerUrl+'/fetch_CarelesSql_controller'
            const {data} = await axios.post(url, fd, config)
            setUser({...user, schoolInfo:data[0]})
    }

    const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(user));
        setIsSubmitting(true);
    }
    const ValidateInput =(values)=>{
        let errors = {};   
       
        if(values.password.length <6){
            errors.password ='Please enter minimum of 6 characters';
        } 

        if(values.confirmPassword.length <6){
            errors.confirmPassword ='Please enter minimum of 6 characters';
        }


      if(values.password!==values.confirmPassword){
          errors.password ='Password does not match.';
      }
        
        return errors;
        }



		useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
            submit()
            }
            },[errors])

            var query = new URLSearchParams(window.location.search)
            var myemail = query.get("myemail")
            var mytoken = query.get("mytoken")

	function submit(){  
		setNotice({...notice,  isLoading: true}) 	
           
        let formData = user;
        const fd = new FormData();
        for(let k in formData){
          fd.append(k, formData[k])
        }

        fd.append('email', myemail)
           let url = ServerUrl+'/update_controller.php?tablename=tbl_update_user_password';
		axios.post(url, fd, config)
		.then(response =>{

	    if (response.data.type ==='success'){
            //Password for “username” has been changed
			setUser({...user, rsMessage:response.data.message, email:'', readyLogin:true})
			
			}else{
				setUser({...user, rsMessageError:response.data, email:''})
				
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
			 
		}).finally(()=>{
            setIsSubmitting(false)
            setNotice({...notice,  isLoading: false }) 
            
        setUser({
            password:'',
            confirmPassword:''
        })
    }) 
}


function verifyLink(){  
		
    
    if(myemail==='null'  || mytoken==='null' || mytoken==='' || myemail===''){
        window.open('/', '_self')
    
    }else{
        setNotice({...notice, isLoading: true}) 
    
          const fd = new FormData();
          fd.append('email', myemail)
          fd.append('token', mytoken)
    
          let url = ServerUrl+'/save_controller.php?tablename=tbl_verify_password_request';
          axios.post(url, fd, config)
          .then(response =>{
          if(response.data.type === 'success'){
                
            setUser({...errors, successMessage: response.data.message})
    
                }else{
                  setErrors({...errors, rsMessage: response.data.message})
                }    
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
                 
            }).finally(()=>{
                setNotice({...notice, 
                    isLoading: false
                }) 
            })    
        } 
    }
    
    
    
    useEffect(()=>{  
        fetchSchoolInfo()
        verifyLink()
    },[]);
    

        return (<div  style={{backgroundImage: `url(${ImagesUrl}/about/${user.schoolInfo.banner})`, backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
             <Preloader />

{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
<section 
className="login-block">
<div className="container">
  <div className="row">
  <div className="col-sm-12">
  {errors.rsMessage?
				<div className='alert alert-danger'>{errors.rsMessage}</div>:''}

</div>
{errors.successMessage?  <div className="col-sm-12">




           
                    
	<form className="md-float-material form-material">
		
	<div className="auth-box card">
		<div className="card-block">
			<div className="row m-b-20">
				<div className="col-md-12">
			
			
{errors.rsMessage?
				<div className='alert alert-success'>{errors.rsMessage}</div>:''}
       
       {errors.rsMessageError?
				<div className='alert alert-danger'>{errors.rsMessageError}</div>:''}

					<h3 className="text-center">Change your password</h3>
				</div>
			</div>


			<div className="form-group form-primary">

            <input type="password" id="password" name="password"  value={user.password} placeholder="Enter New Password" onChange={handleChange} className={errors.password  ? 'form-control formerror' : 'form-control'} />
                            <span className=" form-bar error">{errors.password }</span>

			</div>

            <div className="form-group form-primary">

            <input type="password" name="confirmPassword"   placeholder="Confirm New Password"  value={user.confirmPassword}  onChange={handleChange} className={errors.confirmPassword  ? 'form-control formerror' : 'form-control'} />
                            <span className=" form-bar error">{errors.password }</span>

			</div>


			<div className="row m-t-30">
				<div className="col-md-12">
					{user.readyLogin?<a className="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20" href="/">Click Here to Login.</a>:
					<button type="button" onClick={handleSubmit} className="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20"> <i className="fa fa-key"></i> Update My  Password</button>}
				</div>
			</div>
            <p className="f-w-600 text-right">Back to <a href="/">Login.</a></p>
			<div className="row">
				<div className="col-md-10">
					<p className="text-inverse text-left m-b-0">Thank you.</p>
					<p className="text-inverse text-left"><a href='/' target={'_blank'}><b className="f-w-600">Powered By KAYUS</b></a></p>
				</div>
				<div className="col-md-2">
				<a href='/' target={'_blank'}>
				<img src={ImagesUrl+'/about/kayus.png'} alt="KAYUS" className="img-30" />
				</a>
				</div>
			</div>
		</div>
                            </div>
                        </form>


            </div>:
            
            ''}
            
            </div>
        </div>
    </section>    

         
    </div>

 );
        
}

export default React.memo(RecoverPassword) 