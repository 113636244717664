import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl,  Token,  config,  classID, sectionID, API_URL} from '../../component/include'
import Select from 'react-select'
import { getAge, getClassField, getResultHead, getClassName,  longDate, getNumWorkDays, FormatNumber} from '../component/globalFunction'
import { userToken } from '../component/authentication'
import { useDispatch, useSelector } from 'react-redux'
import { sort } from '../../component/globalFunction'
const ViewResult =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [examtype, setExamtype]=useState([])
    
const [assesmentGrade, setAssesmentGrade]=useState([])
    const [classTeacher, setClassTeacher] = useState([]);
    const [settings, setSettings]=useState([])
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo[0]);

const staff  = useSelector(state => state.rootReducer.staff);
 const [student, setStudent] = useState([]);
 const [studentList, setStudentList] = useState([]);
const [sqlQuery, setSqlQuery] = useState([]);  

const [remarkResults, setRemarkResults] = useState([])
const [studentAssessment, setStudentAssessment]=useState([])
const [assessment, setAssessment]=useState([])
const [grade, setGrade] = useState([])
const [grademark, setGrademark]=useState([])
    const [scores, setScores]=useState([])
    const [onlyCategory, setOnlyCategory]=useState([])

    
    var classType = String(getClassField(classID, schoolClass, 'arms'))


    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        student:[],
        code:'',
        teacherRemark:'',
        teacherName:'',
        headRemark:'',
        headName:'',
        headofSchool:'',
        resultHead:'Secondary'
    })

    const [category, setCategory]=useState([])
    const [attendance, setAttendance]=useState([])
    const [atResult, setAtResult]=useState({
        attendanceStart:'',
        attendanceEnd:'',
        nextTermBegin:'',
        totalOpen:0
    })

    
    const [disabledResult, setDisabledResult]=useState([])

    const handleSearch = event =>{
        event.preventDefault(); 
        
        let errors = {};   
        
        if(result.examtype.length===0){
            errors.examtype ='Please select subject';
        } 
        if(result.orderBy.length===0){
            errors.orderBy ='Please select order of result';
        } 

        setErrors(errors)

        if(Object.keys(errors).length === 0){
            fetchResult()
            }

    } 
     

        const fetchExamType =()=>{
       
            let url = API_URL+'/exam/result/examtype';
            axios.get(url,  config).then(result=>{
            
                let examtype = result.data.examType.filter(item=>item.term !=="MOCK EXAM")
                setExamtype(examtype)
            
                let settings = result.data.grademark;
                var grad = settings[0].grade
            
            var gradeRemar = settings[0].gradeRemark
            var gradeMar = settings[0].gradeMark
            setGrademark(JSON.parse(gradeMar).sort((a, b) =>(a.classtype > b.classtype) ? 1 : -1))
            setGrade(JSON.parse(grad).sort(sort))
            
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            })
            }



    const handleSelect =(option, action)=>{     
          
        setErrors({...errors, [action.name]:''})
        setResult({...result, [action.name]:option, orderBy:[]});
        
        
        fetchStudentList(option.value)
        fetchClassSubject(option.value)
        fetchClassTeaher()
        fetchGradeMark()
            fetchExamAttendance(option.value)
            setScores([])
            setStudent([])
            setStudentAssessment([])
    }

    const handleSelectType =(option, action)=>{  
        
        setResult({...result, [action.name]:option});
        setErrors({...errors, [action.name]:''})
        fetchStatistics()  
        getAttendance()
    }

    

    const fetchStatistics =()=>{
        if(sectionID !==undefined && sectionID !=='')
        setNotice({...notice, isLoading: true}) 
         const fd = new FormData();
     fd.append("exam", result.examtype.value);
     fd.append("sectionID", sectionID);
    fd.append("sql", sqlQuery);
    let url = API_URL+'/exam/result/statistics';
    axios.post(url, fd, config).then(result=>{
         if(result.data.data.length!==0){
    setStudent(result.data.data)
    setRemarkResults(result.data.remarks)
        }else{
            setStudent([])
            setRemarkResults([]) 
        }  
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
        
    }) 
    }

    
    const fetchStudentList =async(exam)=>{
        if(sectionID !==undefined && sectionID !=='')
        setNotice({...notice, isLoading: true}) 
    let url = API_URL+'/student/disabled/'+sectionID+'/'+exam;
            await   axios.get(url,  config).then(result=>{

        if(result.data.data.length!==0){

            setStudentList(result.data.data)

            let studentList = result.data.studentList
            let std = studentList.length!==0?studentList[0].studentList:[]
            const options = std.length!==0? JSON.parse(std):[]

            if(options.length!==0){
                const rs = options.map((item)=>item.studentCode)
                setDisabledResult(rs)
            }else{
                
        setDisabledResult([])
            }

            }else{
                setStudentList([]) 
            } 
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
       
    })
    }
    


    const fetchClassSubject =(exam)=>{
        if(sectionID !==undefined && sectionID !==''){
        let url = API_URL+'/exam/subject_list/'+sectionID+'/'+exam;
        axios.get(url, config).then(response=>{
            if(response.data.data.length!==0){
            var subj = response.data.data;


let sqlString = subj.map(data=>"sum(case when e.subjectCode = '"+data.code+"' and e.absent ='No'  then e.total else 0 end) AS '"+data.subjectName+"'")
                   
             let final =   'SELECT  cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.code, s.passport, s.gender, s.house, s.dateOfBirth, s.admissionNumber, s.registerNumber, s.studentName, sum(case when e.total >0 and e.absent="No" then e.total else 0 end) as obtained, sum(case when e.total >0 and e.absent="No" then 100 else 0 end) as obtainable, e.maxMark, e.absent, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and  e.examCode ="'+exam+'" and e.sectionID ="'+sectionID+'" group by e.studentCode '
    
            setSqlQuery(final)
               
            }else{
                setSqlQuery([])
            }
            })

        }
    }
       
        const getAssessment = (code, field) =>{        
       
            const reply = assessment&&assessment.filter(list=>list.code===code);
            const answer = reply.length!==0?reply[0][field]:'';
            return  String(answer)
            }

          
            const getAssessmentGrade = (code, field) =>{        
       
                const reply = assesmentGrade&&assesmentGrade.filter(list=>list.rateKey===code);
                const answer = reply.length!==0?reply[0][field]:'';
                return  String(answer)
                }


            const getCategory=(code)=>{
                const result = category && category.filter(list=>list.code===code);
              const answer = result.map((c)=>c.categoryName);
              return  String(answer)
            }


        
    const fetchAssessment =()=>{
       
        let url = API_URL+'/exam/result/session/all';
        axios.get(url,  config).then(result=>{

            if(result.data.length!==0){

                try{

                
                let settings = result.data.assessment;
                let standard = JSON.parse(settings[0].assessment)
                let cat = JSON.parse(settings[0].category)
                let config = JSON.parse(result.data.settings[0].settings)
                setCategory(cat.sort(sort))
                setAssessment(standard.sort((a, b) =>(a.categoryCode > b.categoryCode) ? 1 : -1))
                setSettings(config)

                var assesmentGrad = settings[0].assessmentGrade
                setAssesmentGrade(JSON.parse(assesmentGrad).sort((a, b) =>(a.rateKey > b.rateKey) ? 1 : -1))
                
                

            }catch(e){
                    
            }
            }
            
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }



                const fetchStudentAssessment =(assessment)=>{
                    if(assessment.length!==0){
       
                       var assess = assessment;
           var list = [];
           for (var i = 0; i< assess.length;  i++) {
               list.push(
                   {  
                       code:assess[i].assessmentCode,
                       assessmentName:getAssessment(assess[i].assessmentCode, 'assesmentName'),
                       type:getCategory(getAssessment(assess[i].assessmentCode, 'categoryCode')),
                       grade:assess[i].grade,
                       studentCode:assess[i].studentCode,
                       assesmentGrade:getAssessmentGrade(assess[i].grade, 'assesmentGrade')
                   });
                   }
       
                   const onlyCategory =	list.map(e=>e['type'])
                               .map((e,i,final)=>final.indexOf(e)===i&&i)
                               .filter(e=>list[e])
                               .map(e=>list[e])
       
                   setOnlyCategory(onlyCategory)
       
               setStudentAssessment(list)
               
                   }else{
                       setOnlyCategory([])
               setStudentAssessment([])
                   }
               }

       

               const fetchResult =()=>{
                if(sectionID !==undefined && sectionID !==''){
                setNotice({...notice, isLoading: true}) 
                
            let url = API_URL+'/exam/result_assessment/'+sectionID+'/'+result.examtype.value;
            axios.get(url,  config).then(response=>{
                fetchStudentAssessment(response.data.assessment) 
                 if(response.data.data.length!==0){
    
            setScores(response.data.data)
                //getQrcode()
            
            window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
                }else{
                    setScores([])
                    Alerts('Info!', 'info', 'There are no schedule found for the selected exam')
                }  
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice, isLoading: false})
                
            }) 
            }
        }
    

        const fetchGradeMark =(classType)=>{
            if(grademark.length!==0){
                var grade = grademark.filter(item=>item.classtype===classType)
                setGrademark(grade.sort((a, b) =>(parseFloat(b.maximum) > parseFloat(a.maximum)) ? 1 : -1))
            }else{
                setGrademark([])
            }    
            
        }


        const getGradeScore =(code)=>{
            const result = grade&&grade.filter(item=>item.code===code)
            return result.length!==0?result[0].gradeName:''
            }
        


            
            const fetchExamAttendance =(value)=>{
                let url = API_URL+'/exam/attendance/show/'+value
                axios.get(url, config).then(response=>{
                    
                    if(Array.isArray(response.data.data)&& response.data.data.length!==0){
                        setAtResult({...atResult, attendanceStart:response.data.data[0].startDate, 
                            attendanceEnd:response.data.data[0].endDate,
                        nextTermBegin:response.data.data[0].nextTermBegin,
                        nextTermBeginDay: response.data.data[0].nextTermBeginDay })
                       
                    }else{
                        setAtResult({...atResult, attendanceStart:'', attendanceEnd:'', nextTermBegin:'',  nextTermBeginDay:''  })
                    } 
                })
                .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
        
            })
        }

          

           
        const fetchAllHolidays =(holiday)=>{
            var total = parseFloat(getNumWorkDays(atResult.attendanceStart, atResult.attendanceEnd));
            if(Array.isArray(holiday)&& holiday.length!==0){
                setAtResult({...atResult, totalOpen:(total - Number(holiday[0].total))*2 })
                }else{

                    setAtResult({...atResult, totalOpen:total*2 })
                }
        }



        const getAttendance =()=>{
            let url = API_URL+'/student/attendance/statistics/'+atResult.attendanceStart+'/'+atResult.attendanceEnd
            axios.get(url, config).then(response=>{
                if(Array.isArray(response.data.data)&& response.data.data.length!==0){
                    fetchAllHolidays(response.data.holiday)
                    setAttendance(response.data.data)
                }else{
                    setAttendance([])
                } 
            })
            .catch((error)=>{
            //Alerts('Error!', 'danger', error.message)
        })
    }

 
        

            const fetchClassTeaher =()=>{
                var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
                setClassTeacher(newStaff)
            }




            const handleStaffName=(cell)=>{
                if(cell!=='[]' && cell!=''){
                return JSON.parse(cell).map((itm, idx)=>{
                    return <span  key={idx}> {' '+getStaffField(itm, 'staffName')}</span>
                } );
            }else{
                return ''
            } 
            }


            const getStaffField = (code, field) =>{   
    
                const result = staff&&staff.filter(list=>list.code===code);
                const answer = result.map((c)=>c[field] ); 
                return  String(answer);
               }



               const getScores =(code)=>{
                let scr =  [...scores];
                const rs = scr.filter(item=>item.studentCode===code)
                return rs.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1);
          
            
            }
            
            const getStudent =(code)=>{
                let scr =  [...student];
                const rs = scr.filter(item=>item.code===code)
                return rs[0];
            
            }
            
            
            const getAt =(code)=>{
                let scr =  [...attendance];
               const rs = scr.filter(item=>item.code===code)
            
               if(rs.length!==0){
                   return rs[0]
               }else{
                return {A:0, P:0}
               }
               
            
            }
            
            
            const getData =(code)=>{
                 let scr =  [...remarkResults];
                const rs = scr.filter(item=>item.studentCode===code)
                return rs.length!==0?rs[0]:{code:'', teacherRemark:'', headofSchool:'', teacherName:'', headRemark:'', teacherName:'', headName:'', resultHead:'', studentCode:''};
            
            } 
            
            
            const getStudentAssessment =(code)=>{
                let scr =  [...studentAssessment];
                const rs = scr.filter(item=>item.studentCode===code)
                return rs;
            
            }

useEffect(()=>{
    fetchExamType()
    fetchAssessment()
    fetchGradeMark()
},[]);



        return ( <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
               
                   {/* <!-- Page-header start --> */}

        <PageHeader title="View Result">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Terminal Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row no-print ">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Student Terminal Result</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        <div className="row">
     
     <section className="col-md-6">
     <div className="form-group">
          <label> Exam Type <span style={{color:'red'}}>*</span></label>
          <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam, term: list.term }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

 
             </div>
     
     </section>

    
     <section className="col-md-6">
           <div className="form-group">
         <label> Order By</label>
         <Select  options={[
             {value:'order by s.studentName ASC', label:'Order by Name'},
                    { value: 'order by s.registerNumber ASC', label: 'Order By Register Number'},
             { value: 'order by s.admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelectType} className={errors.orderBy ? 'form-control form-control-danger' : ''} name="orderBy" value={result.orderBy}  /> 
<span style={{color:'red'}}>{errors.orderBy && errors.orderBy}</span>

            </div>
     
     </section>
				</div>	


	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search Result</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	

{student.length!==0?studentList.map((std, index)=>

<div key={index}>




{scores.length!==0?
<div className="card z-depth-0">

{result.examtype.term ==='MOCK EXAM'&&getResultHead(classType)!=='Pre-Nursery'?

<div className="card-block">
	
<div className="row table-responsive" >

<table className="table  table-full-width ">
       <tbody>
       <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${schoolInfo.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td className="text-center" >
                        

                 
                        <h4 style={{textAlign:'center'}}><b>{schoolInfo.schoolName}</b></h4>
                        <h6 style={{textAlign:'center'}}><b>Motto: {schoolInfo.schoolMotto}</b></h6>
                        <div style={{textAlign:'center'}}>  {schoolInfo.address}  </div>
                        <div style={{textAlign:'center'}}> {schoolInfo.mobile}, {schoolInfo.altMobile}, {schoolInfo.email} </div>
                        
                        <div style={{textAlign:'center'}}>  Website: {schoolInfo.website}  </div>
                             </td>

 <td > 
    <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  />
{/* 
    <img src={std.qrcode} className="img-100" style={{ height:'100px' }}   /> */}

 </td>
                </tr>
            </tbody>
       </table>

</div> 

<div className="row">
<div className="col-md-12">
<h5 style={{textAlign:'center'}}><u><b> {result.examtype.label} ACADEMIC REPORT</b></u></h5>
</div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

<table className="table table-bordered  table-full-width">
<tbody>

<tr>
                    <td colSpan={2}>NAME OF STUDENT: <b>{std.studentName}</b></td>
                    
                    
                 <td >ADMISSION NO: <b> {std.admissionNumber} </b></td>
                <td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>
                   
                </tr>
               
                <tr>
                    
                    <td colSpan={2} >CLASS: <b> {getClassName(classID,sectionID,schoolClass)} </b></td>
                    <td >GENDER: <b>{std.gender}</b></td> 
                <td >NUMBER IN CLASS: <b>{studentList.length}</b></td>
                   </tr>
               
                

                   <tr>
                    
                   <td>TIME SCHOOL OPENED: <b> {atResult.totalOpen} </b></td>
                    <td>TIMES PRESENT: <b>{(Number(getAt(std.code).P)+Number(getAt(std.code).H))} </b></td>
                    <td colSpan={2}>NEXT TERM BEGIN: <b>{longDate(atResult.nextTermBegin)} </b></td>
                </tr>
                
                   
                <tr>
                    
                    <td colSpan={2}>TOTAL MARK OBTAINABLE: <b>{getStudent(std.code).obtainable}</b></td>
                    <td >TOTAL MARK OBTAINED: <b>{getStudent(std.code).obtained} </b></td>
                    <td >AVERAGE: <b> {getStudent(std.code).average}  </b></td>
                </tr>

        

    </tbody>
</table></div>
<div className="col-md-12 table-responsive">
<table className="table table-striped table-bordered">


    <thead>
        <tr>
            <th >Subjects</th>
           {getResultHead(classType)==='Secondary'? <th >Teacher</th>:''}
           
            <th >Exam (100%)</th>
            <th >Total (100)</th>
            <th >Grade</th>
            <th >SUBJ AVG</th>
            {getResultHead(classType)!=='Secondary'?  <th >Remark</th>:''}
        </tr>
    </thead>
    <tbody>
        
    {getScores(std.code).map((st, idx)=> st.absent!=='Yes' && st.total>0?
        <tr key={idx}>
        
            <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.subjectName.toUpperCase()}</td>
            {getResultHead(classType)==='Secondary'?  <td style={{maxWidth:'120px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(st.teacher)}</td>:''}
            
            <td>{st.exam==='0'?'-':st.exam}</td>
            <td>{st.total==='0'?'-':st.total}</td>
            <td>{st.grade}</td>
            <td>{FormatNumber(st.totalAverage)}</td>
            {getResultHead(classType)!=='Secondary'? <td style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.remark}</td>:''}
        
            </tr>  :[])} 
    </tbody> 
</table>
</div>	 
<div className="col-md-12">
<table className='table table-bordered table-responsive'>
 <tbody>
     <tr>
     <td  style={{ align:'center', valign:'center' }}  colSpan={2} rowSpan={2}><b >KEY TO GRADES </b></td>
         <td><b>Score Range </b></td>
         {grademark.map((data, index)=><td key={index}>{data.minimum + ' - ' + data.maximum}</td>)}

     </tr>
     <tr>
         <td><b>Grade </b></td>
         {grademark.map((data, index)=><td key={index}>{getGradeScore(data.gradeCode)}</td>)}
         
     </tr>
 </tbody>
</table>


{studentAssessment.length!==0?
 <div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td ><table className="table">
                        <thead>
                        <tr>
                            <th>AFFECTIVE DOMAIN</th>
                            <th>REMARK</th>
                        </tr></thead>
                        <tbody>

                            { getStudentAssessment(std.code).map((data, index)=> data.type.toUpperCase()==='AFFECTIVE DOMAIN'?
                            <tr key={index}>
                                <td>{data.assessmentName}</td>
                                <td>{data.grade}</td>
                            </tr>:[])}
                        </tbody>
                    </table>
                    </td>
                    <td ><table className="table">
                        <thead>
                        <tr>
                            <th>PSYCHOMOTOR DOMAIN</th>
                            <th>REMARK</th>
                        </tr></thead>
                        <tbody>
                        {getStudentAssessment(std.code).map((data, index)=> data.type.toUpperCase()==='PSYCHOMOTOR DOMAIN'?
                            <tr key={index}>
                                <td>{data.assessmentName}</td>
                                <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{data.grade}</td>
                            </tr>:[])}
                        </tbody>
                    </table>
                    <table className="table">
                        <thead>
                        <tr>
                            <th colSpan={2}>KEY TO RATINGS</th>
                        </tr></thead>
                        <tbody>
                        {assesmentGrade&&assesmentGrade.map((data, index)=> 
                            <tr key={index}>
                                <td>{data.rateKey}</td>
                                <td>{data.assesmentGrade}</td>
                            </tr>)}
                        </tbody>
                    </table>
                    

                    </td>
                </tr>
                
            </tbody>
       </table></div></div>:''}

<table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'5px' }}><b>Form Tutor(s) </b></td>
                 <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}</td>

             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).teacherRemark} </td>
                 
             </tr>
         </tbody>
     </table>
     {getData(std.code).resultHead==='Nursery' ||getData(std.code).resultHead==='Pre-Nursery'?  <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Nursery </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofNursery, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark}

                </td>
                <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings&&settings.headofNursery, 'signature')}`}   alt={settings&& getStaffField(settings.headofNursery, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>: getData(std.code).resultHead==='Primary'?
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Primary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>:

     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Secondary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>}
<table className='table table-bordered'>
 <tbody>
     <tr>
         <td style={{maxWidth:'10px' }}><b>Head of School </b></td>
         <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofSchool, 'staffName')}</td>
         
         <td style={{maxWidth:'5px' }}> 
         <img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings&&settings.headofSchool, 'signature')}`}   alt={settings&& getStaffField(settings.headofSchool, 'staffName')} /></td>
     </tr>
 </tbody>
</table>
</div>
</div> 

<div className="row no-print">
<div className="col-md-12 pull-right">
<form method='POST' action={ServerUrl+'/printout/student_mock_result.php?token='+userToken} target='_blank'>
        <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
        <input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 
        <input type='hidden' name='studentAssessment' value ={JSON.stringify(studentAssessment, null, 2)} />
        <input type='hidden' name='totalStudent' value ={studentList.length} />
        <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
        <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
        <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />

        <input type='hidden' name='totalOpen' value ={atResult.totalOpen} />
        <input type='hidden' name='classID' value ={classID} />
        <input type='hidden' name='nextTermBegin' value ={longDate(atResult.nextTermBegin)} />
        <input type='hidden' name='sectionID' value ={sectionID} />
        <input type='hidden' name='examCode' value ={result.examtype.value} />

        <input type='hidden' name='resultHeadP' value ={getResultHead(classType)} />
        <input type='hidden' name='onlyCategory' value ={JSON.stringify(onlyCategory, null, 2)} />
      
        <input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} /> 
        <input type='hidden' name='studentList' value ={JSON.stringify(studentList, null, 2)} />
                <input type='hidden' name='nextTermBegin' value ={longDate(atResult.nextTermBegin)} />
                <input type='hidden' name='attendance' value ={JSON.stringify(attendance, null, 2)} />
         

        <input type='hidden' name='jwtToken' value ={Token} />
<button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>

</form>
</div></div>

</div>

    :
    result.examtype.term!=='MOCK EXAM'&&getResultHead(classType)!=='Pre-Nursery'?
		<div className="card-block">
	     <div className="row table-responsive" >

<table className="table  table-full-width ">
       <tbody>
       <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${schoolInfo.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td className="text-center" >
                        

                 
                        <h4 style={{textAlign:'center'}}><b>{schoolInfo.schoolName}</b></h4>
                        <h6 style={{textAlign:'center'}}><b>Motto: {schoolInfo.schoolMotto}</b></h6>
                        <div style={{textAlign:'center'}}>  {schoolInfo.address}  </div>
                        <div style={{textAlign:'center'}}> {schoolInfo.mobile}, {schoolInfo.altMobile}, {schoolInfo.email} </div>
                        
                        <div style={{textAlign:'center'}}>  Website: {schoolInfo.website}  </div>
                             </td>

 <td > 
    <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  />
{/* 
    <img src={std.qrcode} className="img-100" style={{ height:'100px' }}   /> */}

 </td>
                </tr>
            </tbody>
       </table>

</div> 
                   

 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b> {result.examtype.label} ACADEMIC REPORT</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
               
<tr>
                    <td colSpan={2}>NAME OF STUDENT: <b>{std.studentName}</b></td>
                    
                    
                 <td >ADMISSION NO: <b> {std.admissionNumber} </b></td>
                <td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>
                   
                </tr>
               
                <tr>
                    
                    <td colSpan={2} >CLASS: <b> {getClassName(classID,sectionID,schoolClass)} </b></td>
                    <td >GENDER: <b>{std.gender}</b></td> 
                <td >NUMBER IN CLASS: <b>{studentList.length}</b></td>
                   </tr>
               
                

                   <tr>
                   <td>TIME SCHOOL OPENED: <b> {atResult.totalOpen} </b></td>
                    <td>TIMES PRESENT: <b>{(Number(getAt(std.code).P)+Number(getAt(std.code).H))} </b></td>
                    <td colSpan={2}>NEXT TERM BEGIN: <b>{longDate(atResult.nextTermBegin)} </b></td>
                </tr>
                
                   
                <tr>
                    
                    <td colSpan={2}>TOTAL MARK OBTAINABLE: <b>{getStudent(std.code).obtainable}</b></td>
                    <td >TOTAL MARK OBTAINED: <b>{getStudent(std.code).obtained} </b></td>
                    <td >AVERAGE: <b> {getStudent(std.code).average}  </b></td>
                </tr>

                

            </tbody>
       </table></div>
       <div className="col-md-12 table-responsive">
        <table className="table table-striped table-bordered">
        

            <thead>
                <tr>
                    <th >Subjects</th>
                   {getResultHead(classType)==='Secondary'? <th >Teacher</th>:''}
                    <th >1st CAT (20)</th>
                    <th >2nd CAT (20)</th>
                    <th >Exam (60)</th>
                    <th >Total (100)</th>
                    <th >Grade</th>
                    <th >SUBJ AVG</th>
                    {getResultHead(classType)==='Nursery'?  <th >Comment</th>:getResultHead(classType)!=='Secondary'?  <th >Remark</th>:''}
                  
                </tr>
            </thead>

                
            {getScores(std.code).map((st, idx)=> st.absent!=='Yes' && st.total>0?  <tbody key={idx}>
                
                <tr>
                
                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.subjectName.toUpperCase()}</td>
                    {getResultHead(classType)==='Secondary'?  <td style={{maxWidth:'120px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(st.teacher)}</td>:''}
                    <td>{st.firstCA==='0'?'-':st.firstCA}</td>
                    <td>{st.secondCA==='0'?'-':st.secondCA}</td>
                    <td>{st.exam==='0'?'-':st.exam}</td>
                    <td>{st.total==='0'?'-':st.total}</td>
                    <td>{st.grade}</td>
                    <td>{FormatNumber(st.totalAverage)}</td>
                    {getResultHead(classType)==='Nursery'? <td style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.comment}</td>:getResultHead(classType)!=='Secondary'? <td style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.remark}</td>:''}
                
            </tr>
            {st.comment!=='' && getResultHead(classType)==='Secondary'? 
                <tr>
                    
                    <td  >Subject Comment </td>
                    <td colSpan='7' style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.comment}</td>
                </tr>:[]}
          
            </tbody>  :[])}



        </table>
 </div>	 
 <div className="col-md-12">
     <h6>*CAT - Continuous Assessment Test</h6>
     <table className='table table-bordered table-responsive'>
         <tbody>
             <tr>
             <td  style={{ align:'center', valign:'center' }}  colSpan={2} rowSpan={2}><b >KEY TO GRADES </b></td>
                 <td><b>Score Range </b></td>
                 {grademark.map((data, index)=><td key={index}>{data.minimum + ' - ' + data.maximum}</td>)}

             </tr>
             <tr>
                 <td><b>Grade </b></td>
                 {grademark.map((data, index)=><td key={index}>{getGradeScore(data.gradeCode)}</td>)}
                 
             </tr>
         </tbody>
     </table>

    
     {studentAssessment.length!==0?
 <div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td ><table className="table">
                        <thead>
                        <tr>
                            <th>AFFECTIVE DOMAIN</th>
                            <th>REMARK</th>
                        </tr></thead>
                        <tbody>

                            { getStudentAssessment(std.code).map((data, index)=> data.type.toUpperCase()==='AFFECTIVE DOMAIN'?
                            <tr key={index}>
                                <td>{data.assessmentName}</td>
                                <td>{data.grade}</td>
                            </tr>:[])}
                        </tbody>
                    </table>
                    </td>
                    <td ><table className="table">
                        <thead>
                        <tr>
                            <th>PSYCHOMOTOR DOMAIN</th>
                            <th>REMARK</th>
                        </tr></thead>
                        <tbody>
                        {getStudentAssessment(std.code).map((data, index)=> data.type.toUpperCase()==='PSYCHOMOTOR DOMAIN'?
                            <tr key={index}>
                                <td>{data.assessmentName}</td>
                                <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{data.grade}</td>
                            </tr>:[])}
                        </tbody>
                    </table>
                   
                    
                    <table className="table">
                        <thead>
                        <tr>
                            <th colSpan={2}>KEY TO RATINGS</th>
                        </tr></thead>
                        <tbody>
                        {assesmentGrade&&assesmentGrade.map((data, index)=> 
                            <tr key={index}>
                                <td>{data.rateKey}</td>
                                <td>{data.assesmentGrade}</td>
                            </tr>)}
                        </tbody>
                    </table>
                    </td>
                </tr>
                
            </tbody>
       </table></div></div>:''}
 <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'5px' }}><b>Form Tutor(s) </b></td>
                 <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}</td>

             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).teacherRemark} </td>
                 
             </tr>
         </tbody>
     </table>
    
     {getData(std.code).resultHead==='Nursery' ||getData(std.code).resultHead==='Pre-Nursery'?  <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Nursery </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofNursery, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark}

                </td>
                <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings&&settings.headofNursery, 'signature')}`}   alt={settings&& getStaffField(settings.headofNursery, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>:getData(std.code).resultHead==='Primary'?
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Primary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>:

     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Secondary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>}
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }}><b>Head of School </b></td>
                 <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofSchool, 'staffName')}</td>
                 
                 <td style={{maxWidth:'5px' }}> 
                 <img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings&&settings.headofSchool, 'signature')}`}   alt={settings&& getStaffField(settings.headofSchool, 'staffName')} /></td>
             </tr>
         </tbody>
     </table>
 </div>
 </div> 
 
 <div className="row no-print">
        <div className="col-md-12 pull-right">
        <form method='POST' action={ServerUrl+'/printout/student_result.php?token='+userToken} target='_blank'>
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                <input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 
                <input type='hidden' name='studentAssessment' value ={JSON.stringify(studentAssessment, null, 2)} />
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />

                <input type='hidden' name='resultHeadP' value ={getResultHead(classType)} />
                <input type='hidden' name='classID' value ={classID} />
                <input type='hidden' name='nextTermBegin' value ={longDate(atResult.nextTermBegin)} />
                <input type='hidden' name='sectionID' value ={sectionID} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />

                <input type='hidden' name='totalOpen' value ={atResult.totalOpen} />
                <input type='hidden' name='onlyCategory' value ={JSON.stringify(onlyCategory, null, 2)} />
               
                <input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} /> 
        <input type='hidden' name='studentList' value ={JSON.stringify(studentList, null, 2)} />
                <input type='hidden' name='nextTermBegin' value ={longDate(atResult.nextTermBegin)} />
                <input type='hidden' name='attendance' value ={JSON.stringify(attendance, null, 2)} />
                 

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>:''}

 {disabledResult&&disabledResult.includes(std.code)? 
<div style={{ 

height:'100%',
backgroundColor:'black',
width:'100%',
alignItems:'center',
justifyContent:'center',
flexDirection:'column',
display:'flex',
flex:1,
position:'absolute',
padding:'20px',
opacity:0.98


 }}>


<h3 style={{ color:'white',  }}> Result not available at the momemt, please contact the Account's Department</h3>

</div>
:''}  
        </div>:''}






</div>):[]}

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ViewResult) 