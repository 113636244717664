import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token,  config, allowDelete, allowEdit, menuLink } from '../component/include'
import {CSV, SaveCSV} from '../component/images'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { formatGroupLabel, getClassName } from '../component/globalFunction'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

 const UploadStudents=()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

    const [data, setData] =  useState([]);
    const [CurrentSession, setCurrentSession] = useState('')
const [csvFile, setCsvFile]=useState({
    studentCsv:'',
    fileName:'Please select .csv files (allowed file size 500KB)',
    sessionID:CurrentSession&&CurrentSession, 
})
let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
const [students, setStudents] = useState([])
const [errors, setErrors] = useState({});

		const  handleSession =()=>{
		
			var sql ="Select  startYear from tbl_school_session where status ='Active'"
			const fd = new FormData();
			fd.append("sql", sql);
			fd.append("jwt", Token);
			let url = ServerUrl+'/fetchBySql_controller';
			axios.post(url, fd, config)
			.then(result => {
				if(result.data.length!==0){
				setCurrentSession(result.data[0].startYear)
			}
			})
			.catch((error)=>{
				Alerts('Error!', 'danger', JSON.stringify(error.message)) 
			})
			} 
const  handleFetchStudents =()=>{
    
    const fd = new FormData();
    var sql ="Select admissionNumber, studentName, status, time_submit from tbl_students where insertMode ='csv'"; 
    fd.append("sql", sql);
    fd.append("jwt", Token);
    
   
    let url = ServerUrl+'/fetchBySql_controller';
    setNotice({...notice,  isDataFetching: true });
    axios.post(url, fd, config)
    .then(result => {
        setStudents(result.data)
    
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isDataFetching: false
        }); 
    })   
        }


const  handleSubmit=(event)=>{ 
    event.preventDefault(); 
    if(!csvFile.studentCsv){
        errors.studentCsv ='You have not choose any file to upload';
        Alerts('Error!', 'danger', errors.studentCsv)
    }else{

    
    let formData = csvFile;
    
    const fd = new FormData(); 
     for(let k in formData){
        fd.append(k,formData[k]);
    };  
    fd.append('jwt', Token)
    fd.append('regSession', CurrentSession)
    
    
     setNotice({...notice,   isLoading: true}) 
       let url = ServerUrl+'/save_controller/importStudents';
      axios.post(url, fd, config).then(response =>{
        if (response.data.type ==='success'){
			Alerts('Saved!', 'success', response.data.message)
		} else if (response.data.type ==='info'){
            Alerts('Saved!', 'default', response.data.message)
            
        }else{
			setErrors({...errors, errorMessage: JSON.stringify(response.data)}) 
	}  
	})
	.catch((error)=>{
		//setErrors({...errors, errorMessage: JSON.stringify(error.message)}) 
	})
	.finally(()=>{
	  setNotice({...notice, isLoading: false}) 
     setCsvFile({...csvFile, studentCsv:'', fileName:'Please select .csv files (allowed file size 500KB)'}); 
     document.getElementById('studentCsv').value=''
}) 
}
 }






    const  handleFile = function(fieldName){
        return function(newValue){  
           
            setErrors({...errors, [fieldName]:''})
       readURL(newValue);
       }
       
    } 
   

    const readURL = (input)=>{
       
        let displayMessage = '';
        const PreviewImage = input.target.name;
            var fileName = document.getElementById(PreviewImage).value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        

        if (extFile==="csv" || extFile==="CSV"){
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 20) {
            displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 500 Kb';
           document.getElementById(input.target.name).value = '';
        } else {
            if (input.target.files && input.target.files[0]) { //Check if input has files.
                   const value = input.target.files[0]
            setCsvFile({...csvFile, studentCsv:value, fileName:value.name})
           
            }
        }
        }else{

            displayMessage = 'Only csv|CSV  files are allowed with maximum of 500kb'
            document.getElementById(input.target.name).value = '';
            setCsvFile({...csvFile, studentCsv:'', fileName:'Please select .csv files (allowed file size 500KB)'})
        }  
        
        if(displayMessage.length > 0){
            setErrors({...errors, errorMessage: JSON.stringify(displayMessage)})
            //Alerts('Error!', 'danger', displayMessage)
          }
        
    } 



    
    const StudentTable=(props)=>{

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
  
        
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                 
      
      <div className="dt-buttons btn-group pull-right"> 
      <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'200px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>
    </div> 

                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    

      let i =1;
      const tableHeader = [
          {dataField: '', text: 'SN', formatter: ()=>i++, editable: false},    
          {dataField: 'admissionNumber', text: 'Admission Number', sort: true, editable:false}, 
          {dataField: 'studentName', text: 'Student Name', editable: false},
          {dataField: 'status',  text: 'Status', formatter:()=>'Successfully registered student', editable: false, sort: true},
          {dataField: 'time_submit',  text: 'Action Date', sort: true, editable:false}
         
       ];




       const tableClassHeader = [
        
        {dataField: 'classID', text: 'Class ID', editable: false}, 
        {dataField: 'sectionID', text: 'Section ID', editable: false},
        {dataField: 'code', text: 'Class', editable: false, formatter:(cell, row)=>getClassName(row.classID, row.sectionID, schoolClass)}
        
     ];


     const ClassTable=(props)=>{
        

          
              const { SearchBar } = Search;
             
              const customTotal = (from, to, size) => (
                 <span >&nbsp;Showing { from } to { to } of { size } items</span>
               );
            const options = {
                showTotal: false,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
            { text: 'All', value: props.data.length }]
               
              };
          
            return <ToolkitProvider search className="table-responsive"
                        keyField='ID' data={ props.data } columns={ props.columns } >
                            
                            {
                                props => (
                                <div >
                                    <SearchBar
                                    { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                                    
    <div className="dt-buttons btn-group pull-right"> 
             
             <a href="/setup/school_classes" className="btn btn-outline-primary" ><i className="fa fa-pencil"></i>Add More Class</a>
          </div> 
                                    <BootstrapTable
                                    { ...props.baseProps }
                                            hover
                                            noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                        pagination={paginationFactory(options) }
                                       
                                        
                                    />
                                </div>
                                )
                            }
          </ToolkitProvider>
          }


          const  handleFetchClassSubject =()=>{
            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl + '/fetch_controller/tbl_class_subject';
            axios.post(url, fd, config)
        .then(result =>   setData(result.data)
        )
        .catch((error)=>{
            //Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        }) }


        useEffect(()=>{   
         
    setIsAuthenticated(menuLink.includes(location.pathname))
            handleFetchStudents();
            handleSession()
            handleFetchClassSubject()
        },[]);

        return (  <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 

                   {/* <!-- Page-header start --> */}

        <PageHeader title="Upload Students From Excel">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-graduation-cap"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="/students/view_students">Students</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Students Upload</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


                {errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}
        


                <div className="row  ">
								<div className="col-sm-6">
	<div className="card z-depth-0">
		<div className="card-block">
		<fieldset>
			<div className="row">
									
			<section className="col-md-12 ">
			<div className="well well-sm alert alert-success border-success background-success ">
					<h4 className="alert-heading ">Download Sample File</h4>
					
<p><span >The first line in the downloaded sample file should remain as it is. Please do not change the order of columns.</span><br/>

<span >The correct column order is (<b>
Register Number, Admission Number, Student Name, Gender, Student Email,  Mobile, Admission Date, Date Of Birth, Class ID, Section ID, Father Name, Mother Name </b>)</span> <span >&amp; you must follow this.</span></p>
					<hr className="simple" /><p className="text-align-left">
						
                    <form method='POST' action={ServerUrl+'/csvSample_controller/students'} target='_blank'>
                <input type='hidden' name='jwt' value ={Token} />
                
        <button type="submit" className="btn btn-sm btn-inverse"> <i className="fa fa-download"></i> Download Sample</button>
        
        </form>
					</p>
				</div>					
				</section>
										</div>
										
				</fieldset>
				
							</div>
							</div>
							</div>
		
	
	

	<div className="col-sm-6">
	<div className="card z-depth-0">
	
		<div className="card-block">


		<div className="alert alert-success border-success">
						<fieldset className="well well-sm">
							<h4 className="alert-heading ">Upload file with .csv extention</h4>
							
							<section>
                            <div className="form-group">
							<label  className={errors.studentCsv  ? 'btn btn-sm btn-danger' : 'btn btn-sm btn-inverse'}  htmlFor="studentCsv" > <i className="fa fa-upload"></i>Choose File </label>
                    <input id="studentCsv"  onChange={handleFile('studentCsv')} className="form-control file" name="studentCsv" type="file" accept=".csv" /> 
						<div className="note" style={{color:'red'}}>
			{csvFile.fileName}
		</div>	
										</div>          
                                        
							</section>
						</fieldset><span style={{color:'red'}}>{errors.studentCsv && errors.studentCsv}</span></div>
						<footer>
							<button type="button" id="submit" onClick={handleSubmit} className="btn  btn-primary pull-right"><i className="fa fa-save"></i> Upload Students</button>
						</footer>

	</div>

</div>

</div></div>
	

								<div className="row  ">
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>View Class List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block">

		  <div className="card z-depth-0">
            <div className="card-block">
          <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <ClassTable data={data}  columns={tableClassHeader}  />}
        </div> </div></div>
	</div>
    </div>
    </div>
</div>


<div className="row  ">
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5>How to convert Excel file to CSV</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block" >
		<h3>How to convert Excel file to CSV</h3>
        
                <ol>
                    <li>Open your excel file.</li>
                    <li>Click on <b>File</b> and <b>Save as</b>.</li>
                    <li>Select where you would like to save the file (like your desktop or a drive).<br/>
                    <img src={SaveCSV} alt=''/>
                    </li>
                    <li>In the <b>File Name</b> area, name your document.</li>
                    <li>In the <b>Save as Type</b> area, select <b>CSV(Comma Delimited)</b> from the pull down menu.<br/>
                    Besides CSV (comma delimited), there is CSV (Macintosh) and CSV (MS-DOS) formats available. Do not save the file as these formats.<br/>
                    <img src={CSV} style={{height:'300px'}} alt='' />
                    </li>
                    <li>Click <b>Save</b>.</li>
                    <li>You may receive one or two warning about the formatting of .csv, click <b>yes</b>.</li>
                </ol>
                    
                    <hr/>
                    <div className="well">
                        <h4 className="text-bold">Prepare your Students file:</h4>
                        <div className="box-body">
                        <div className="row">


                            <div className="col-sm-3 text-right  text-danger"><u><b>Required Columns</b></u></div>
                            <div className="col-sm-7"><u><b>Allowed Values</b></u></div>                           
                                								
                            <div className="col-sm-3 text-right text-bold">Student Name</div>
                        <div className="col-sm-7">Name of that particular Student       <br/>  </div>   


                        <div className="col-sm-3 text-right text-bold">Gender</div>
                        <div className="col-sm-7">Sex of that particular Student       <br/>  </div>

                        <div className="col-sm-3 text-right text-bold">Date of Admission</div>
                        <div className="col-sm-7">The date in which student information was captured       <br/>  </div>

                        <div className="col-sm-3 text-right text-bold">Student Email</div>
                            <div className="col-sm-7">Email Address of the student, this will be used to recover password as well<br/>                           
                                </div> 

                                <div className="col-sm-3 text-right "><b>Admission Number</b></div>
                    <div className="col-sm-7">Unique Admission Number. Each student  are assigned their unique admission number and should be entered correctly. <br/>                       </div> 
                        <div className="col-sm-3 text-right text-bold">Class ID</div>
                        <div className="col-sm-7">The class ID in which the student will be admitted to      </div>

                        <div className="col-sm-3 text-right text-bold">Section ID</div>
                        <div className="col-sm-7">The Section ID in which the student will be admitted to   <br/>    <br/>  </div>
                        <div className="col-sm-3 text-right  text-warning"><u><b>Optional Columns</b></u></div>
                            <div className="col-sm-7 "><u><b>Allowed Values</b></u></div> 
                          

                          <div className="col-sm-3 text-right "><b>Father Name</b></div>
                            <div className="col-sm-7">The name of the father of that particular student<br/>                          
                                </div>                       
                           
								
								
								<div className="col-sm-3 text-right "><b>Mother Name</b></div>
                            <div className="col-sm-7">Student Mother's Name  <br/>     
                                </div> 
								<div className="col-sm-3 text-right "><b>Date of Birth</b></div>
                            <div className="col-sm-7">Student date of birth  <br/>     
                                </div> 
                             
                            <div className="col-sm-3 text-right "><b>Register Number</b></div>
                    <div className="col-sm-7">Unique register Number. Each student  are assigned their unique register number and should be entered correctly. <br/>                       </div>                          
                            <div className="col-sm-3 text-right "><b>Student Mobile</b></div>
                            <div className="col-sm-7">Any<br/>                          
                                </div>
                        </div>
                    </div>
                </div>
	</div>
    </div>

</div>
</div>
                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(UploadStudents) 