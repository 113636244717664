import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {Currency,  ImagesUrl, ServerUrl,  Token, config, allowDelete, allowEdit, menuLink} from '../component/include'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import Select from 'react-select'
import {  longDate, FormatNumber} from '../component/globalFunction'
import { userToken } from '../component/authentication'
import { useDispatch, useSelector } from 'react-redux'

const StaffSalary =()=> {
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState([])
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const [payment, setPayment] = useState({
       staff:[],
       fromDate:'',
        toDate:'',
    })
    const staff  = useSelector(state => state.rootReducer.staff);
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);

        
        const handleSelect= (option, action)=>{
            setPayment({...payment, [action.name]:option});
            setErrors({...errors, [action.name]:''})
            setResult('')
        }

        const handleChange=(event)=>{
            const {name, value} = event.target;
            setPayment({...payment, [name]:value})
            setErrors({...errors, [name]:''})
            setResult('')
            
        }
    
            const fetchResult =()=>{
               
            let sql = "Select s.ID, s.code, f.fileNo, f.staffName,  s.paymentMonth, s.paymentDate, s.totalAmount, p.paymentType from tbl_staff f, tbl_salary_payment s, tbl_payment_type p  where f.code = s.staff and  s.paymentType = p.code "


            if(payment.staff.length !== 0){
                sql = sql+ " and s.staff = '"+payment.staff.value+"'"
            }

            if(payment.fromDate !=='' && payment.toDate!==''){
                sql = sql+ "   and s.paymentMonth >= '"+payment.fromDate+"' and s.paymentMonth <= '"+payment.toDate+"'"
            }

            sql = sql +"  order by s.paymentMonth DESC"
  setNotice({...notice, isLoading: true}) 
  const fd = new FormData();
  fd.append('jwt', Token);
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller'
            axios.post(url, fd, config).then(result=>{
                if(result.data.length!==0){
                    setResult(result.data)
                }else{
                    Alerts('Info!', 'default', 'No record found for the selected criterials')
                }
                
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice, isLoading: false})
                
            })   
        } 
            
    useEffect(()=>{
        
    setIsAuthenticated(menuLink.includes(location.pathname)) 
    }, [])

        return (   <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Staff Salary">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Staff Salary</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
						
                        <div className="col-sm-12">
                    
                        <div className="card z-depth-0">
                            <div className="card-header">
                                <h5><i className="fa fa-edit" ></i> Staff Salary</h5>
                                <div className="card-header-right">
                                    <ul className="list-unstyled card-option">
                                        <li><i className="feather icon-maximizes full-card"></i></li>
                                        <li><i className="feather icon-minus minimize-card"></i></li>
                                        <li><i className="feather icon-trash-2 close-card"></i></li>
                                    </ul>
                                </div>
                            </div>
                            
                            
                            <div className="card-block">
        
        <div  className="card-block">
        <div  className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
        <section className="col-md-6">
   <div className="form-group">
<label >Select Staff </label>
      <Select  options={
                            staff.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.fileNo, staffName:list.staffName}
                             })} 
                        getOptionLabel={option =>`${option.label} - ${option.staffName}`}
onChange={handleSelect}  name="staff" value={payment.staff}  /> 
   </div>

</section>
      
      <section className="col-md-3">
      <div className="form-group">
   <label >From Date</label>
   <input type="month" className='form-control' name="fromDate"   onChange={handleChange} value={payment.fromDate} />

               </div>
</section>
      	
      
   <section className="col-md-3">
      <div className="form-group">
   <label >To Date</label>
   <input type="month" className='form-control' name="toDate"   onChange={handleChange} value={payment.toDate} />

               </div>
</section>
</div>	

<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={()=>fetchResult()} className="btn btn-primary "><i className="fa fa-search"></i> Search</button>
								</footer>		</section>		
                                </div>
                            
                            
                            </div></div> 
							</div></div>
</div>
</div>
</div>



{result.length!==0?
    <div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <table className='table' key={id}>
       <tbody>
                <tr>
                <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
                </tr>
            </tbody>
       </table>  )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>STAFF SALARY </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Date Printed: <b>{longDate(new Date())}</b></td>
                    <td >Month Range: From <b>{longDate(payment.fromDate)+ ' to '+ longDate(payment.toDate)}</b></td>
                </tr>
            </tbody>
       </table>
       <table className="table table-striped table-bordered">
        <thead>
                <tr>
                <th>Staff</th>
                <th>Invoice No</th>
                <th>Paid Month</th>
                <th>Payment Date</th>
                    <th>Payment Mode</th>
                    <th>Amount ({Currency})</th>
                    
                </tr>
              </thead>
              <tbody>
              {result.length!==0?result.map((st, idx)=> 
                <tr key={idx}>
                <td>{st.fileNo+' - '+st.staffName}</td>
                <td>{st.ID}</td>
                <td>{st.paymentMonth}</td>
                <td>{longDate(st.paymentDate)}</td>
                <td>{st.paymentType}</td>
                <td>{FormatNumber(st.totalAmount)}</td>
                </tr>):[]}
              </tbody>
           
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">

        <form method='POST' action={ServerUrl+'/printout/staff_salary.php?token='+userToken} target='_blank'>
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='month' value ={longDate(payment.fromDate)+ ' to '+ longDate(payment.toDate)} />
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>

        </div></div>

 </div>
        </div>:''}



   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(StaffSalary) 