import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, FormatNumber, Currency, config, allowDelete, allowEdit} from '../component/include'
import Select from 'react-select'

const AssignSalaryStructure =()=> {
    const [errors, setErrors] = useState({});
    
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
const [staff, setStaff]= useState([])
const [template, setTemplate]=useState([])
const [leave, setLeave]=useState([])
const [salary, setSalary]=useState([]);
const [title, setTitle]=useState([])

    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const [salaryStructure, setSalaryStructure]=useState([]);

    const [structure, setStructure] = useState({
        template:[],
        staff:[],
        effectiveMonth:'',
        remarks:''
    })
    
    const fetchStaff =()=>{

        let sql ="SELECT staffName, passport, code, fileNo from tbl_staff WHERE employmentStatus ='Working' ";

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
            if(result.data.length!==0){
            setStaff(result.data)
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }



    const  fetchTemplate =()=>{  
        const fd = new FormData();
        fd.append('jwt', Token); 
        let url = ServerUrl+'/fetch_controller/tbl_salary_template'
        axios.post(url, fd, config).then(result=>setTemplate(result.data))
    
     } 

     const handleChange=(event)=>{
        setStructure({...structure, [event.target.name]:event.target.value});
        setErrors({...errors, [event.target.name]:''})
    }

    const fetchLeave =()=>{
        const fd = new FormData();
        fd.append('jwt', Token); 
        let url = ServerUrl+'/fetch_controller/tbl_staff_leave_settings'
        axios.post(url, fd, config).then(result=>setLeave(result.data))
    }

    const  fetchTitle =()=>{  
        const fd = new FormData();
        fd.append('jwt', Token); 
        let url = ServerUrl+'/fetch_controller/tbl_salary_title'
        axios.post(url, fd, config).then(result=>setTitle(result.data))
    
     } 

    const handleChangeLeave = (e, index)=>{
        const std =JSON.parse(JSON.stringify(leave))   
           const value =e.target.value;
           std[index][e.target.name] = value;
       setLeave(std); 
   }

   const handleSelect= (option, action)=>{
    setStructure({...structure, [action.name]:option});
    setErrors({...errors, [action.name]:''})

    if(action.name==='template'){
        fetchTemplateTitle(option.value)            
    }
    if(action.name==='staff'){          
    fetchSalaryStructure(option.value);
    }
}
const  fetchTemplateTitle =(code)=>{          
    const result = template.filter(list=>list.code===code);
   const answer = result.map(c=>c.template); 

   let value = []
   JSON.parse(answer).map((itm, idx)=>{
       value.push({code:itm.titleCode, salaryHead:getTitle(itm.titleCode, 'salaryHead'), title:getTitle(itm.titleCode, 'title'), salaryCode:getTitle(itm.titleCode, 'salaryCode'), amount:'', key:idx});
       return ''
   } );

   setSalary(value)
 } 

   const getTitle = (code, field) =>{ 
    const result = title.filter(list=>list.code===code);
   const answer = result.map(c=>c[field]); 
     return  String(answer);
   }

   const handleChangeTitle = (e, index)=>{
    const std =JSON.parse(JSON.stringify(salary))   
       let value =e.target.value;

       if(e.target.name==='amount'){
        value = value.replace(/[^0-9]/g, '');
      }

      std[index][e.target.name] = value;
      setSalary(std); 
}


const handleSubmit = event =>{
    event.preventDefault();    
    setErrors(ValidateErrors(structure));
    setIsSubmitting(true);
}  
const ValidateErrors=(values)=>{
    let errors = {};   
    if(values.staff.length===0){
        errors.staff ='Please select staff';
    } 
    if(values.template.length===0){
        errors.template ='Please select salary Template';
    } 
    if(!values.effectiveMonth){
        errors.effectiveMonth ='Please select effective month';
    } 
    return errors;
    }

    
  
useEffect(()=>{
if(Object.keys(errors).length === 0 && isSubmitting){
submit()
}
},[errors])


const  submit=()=>{  
          
setNotice({...notice, 
    isLoading: true})   

     const fd = new FormData();
          fd.append('jwt', Token); 

    fd.append('effectiveMonth', structure.effectiveMonth);
    fd.append('remarks', structure.remarks);
    fd.append('staff', structure.staff.value);
    fd.append('template', structure.template.value);
    fd.append('leaveStructure', JSON.stringify(leave, null, 2));
    fd.append('salary', JSON.stringify(salary, null, 2)); 

     let url = ServerUrl+'/save_controller/tbl_salary_structure'
    axios.post(url, fd, config)
    .then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                }else if(response.data.type ==='info'){
                    Alerts('update!', 'default', response.data.message)
                        } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            })
        setIsSubmitting(false)
        setStructure({
        template:[],
        staff:[],
        effectiveMonth:'',
        remarks:''})
        setSalary('')
        setSalaryStructure('');
 } )
}


    const  fetchSalaryStructure =(staff)=>{    
        const fd = new FormData();
        fd.append('jwt', Token); 
   let sql = "Select s.ID, s.code, t.templateName, s.effectiveMonth, s.remark, s.leaveStructure, s.salary, s.dateCreate from tbl_salary_structure s, tbl_salary_template t where t.code = s.template and s.staff='"+staff+"' order by s.ID DESC"

       let url = ServerUrl+'/fetchBySql_controller'
     
       fd.append("sql", sql)
       
       axios.post(url, fd, config).then(result =>setSalaryStructure(result.data)) 
    }   



    const getTotal =(salary)=>{
        var amount =0
        JSON.parse(salary).map((sl)=>
        sl.salaryHead==='EARNING'?
            amount += Number(sl.amount):amount -=Number(sl.amount)
        )
        return amount
    }

    

   

    const handleDelete = (code)=>{  
        setNotice({...notice, 
           isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
              const fd = new FormData();
              fd.append('jwt', Token); 
           fd.append('ID', 'code')
            fd.append('data', code)
    
            let url = ServerUrl+'/delete_controller/tbl_salary_structure'
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
            fetchSalaryStructure(structure.staff.value)
               setNotice({...notice, 
                   isLoading: false
               })
           }) 
    }
    
          

useEffect(()=>{
    fetchStaff()
fetchTemplate()
fetchLeave()
fetchTitle()
},[]);



        return (  
            <React.Fragment>
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Staff Salary & Leave">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Assign Salary Structure</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
     
      <section className="col-md-6">
   <div className="form-group">
<label >Select Staff</label>
      <Select  options={
                            staff.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.fileNo, staffName:list.staffName}
                             })} 
                        getOptionLabel={option =>` ${option.staffName} - ${option.label} `}
onChange={handleSelect} className={errors.staff ? 'form-control form-control-danger' : ''} name="staff" value={structure.staff}  /> <span style={{color:'red'}}>{errors.staff && errors.staff}</span>
   </div>

</section>

<section className="col-md-6">
   <div className="form-group">
<label >Salary Template</label>
      <Select  options={
                            template.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.templateName}
                             })} 
onChange={handleSelect} className={errors.template ? 'form-control form-control-danger' : ''} name="template" value={structure.template}  /> <span style={{color:'red'}}>{errors.template && errors.template}</span>
   </div>

</section>

<section className="col-md-6">
   

   <div className="form-group">
<label >Effective Month</label>
<input type="month" className={errors.effectiveMonth ? 'form-control form-control-danger' : 'form-control'} name="effectiveMonth"  onChange={handleChange} value={structure.effectiveMonth} />
<span style={{color:'red'}}>{errors.effectiveMonth && errors.effectiveMonth}</span>
   </div>

</section>
<section className="col-md-6">
<div className="form-group">
						<label >Remarks</label>
		<input type="text" name="remarks" value={structure.remarks} onChange={handleChange} className='form-control' /> 
			</div>

      </section>	
      
<section className="col-md-6" style={{display:salary.length===0?'none':'block'}} >
           
           <label ><b>Salary Structure</b></label> <hr/>
     {salary&&salary.map((tit, id)=>
     <div key={id} className="form-group">
                             <label >{tit.title} </label> 
             <input  name='amount' type="text" min='0' onFocus={(e)=>e.target.select()} value={tit.amount} onChange={(e)=>handleChangeTitle(e, id)} className='form-control' /> 
                 </div>
     )}
           
           </section> 


      <section className="col-md-6" style={{display:structure.staff.length===0?'none':'block'}} >
      
      <label ><b>Leave Structure</b></label> <hr/>
{leave&&leave.map((lv, id)=>
<div  key={id} className="form-group">
						<label >{lv.leaveName} </label> <label style={{color:'green'}} className="pull-right" ><b>Time Circle : {lv.timeCycle} </b></label>
		<input type="number" min="0" name='days' value={lv.days} onChange={(e)=>handleChangeLeave(e, id)} className='form-control' /> 
			</div>
)}

      </section>	




				</div>	

<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-primary "><i className="fa fa-save"></i> Save</button>
								</footer>		</section>		
							</div>
                            
                            
                            </div> </div> 
							
</div>

</div>



<div style={{display:salaryStructure.length===0?'none':'block'}} className="row  ">   <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Salary Structure</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12">
            <div className="table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <table className="table">
             <thead>
                 <tr>
                    <th>Action</th>
                 <th>Salary Template</th>
                 <th>Fixed Salary</th>
                 <th>Month Effective</th>
                 <th>Remarks</th>
                 </tr>
             </thead>
           <tbody >
               {salaryStructure&&salaryStructure.map((st, id)=>
             <tr key={id}>
          <td>

          {allowDelete==='Yes'?<a href="#!" data-toggle="modal" className='btn btn-danger'  data-target={'#warningdialog-'+st.code}     title="Delete"><i className="icofont icofont-delete-alt"></i></a>:[]}
        
   <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(st.code)} mID={st.code} /> 
   </td> 
               <td>{st.templateName}</td>
             <td>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Title</th>
                            <th>Salary Amount</th>
                        </tr>
                        </thead>
                        <tbody >
                            {JSON.parse(st.salary).map((sl, idx)=>
                        <tr key={idx}>
                        <td style={{color:sl.salaryHead==='EARNING'?'green':'red'}}>{sl.title} ({sl.salaryCode})</td>
                        <td>{sl.amount}</td>
                        </tr>
                        )}
                        </tbody>
                        <tfoot>
                        <tr>
                            <th>Total</th>
                            <th>{Currency+FormatNumber(getTotal(st.salary))}</th>
                        </tr>
                        </tfoot>
                    </table>
                 
                 </td>
               <td>{st.effectiveMonth}</td>
               <td>{st.remark}</td>
             </tr>
             )}
           </tbody>
           </table> 
        } 

            </div></div></div>


    
    </div>
    
    </div>
    </div>


   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(AssignSalaryStructure) 