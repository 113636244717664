import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import PageHeader from '../component/pageheader'
import { Alerts } from '../component/notify'

import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import {ImagesUrl, ServerUrl, Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import axios from 'axios'
import {  formatGroupLabel, getResultHead} from '../component/globalFunction'
import { userToken } from '../component/authentication'
import Error403 from '../settings/error403'


const Documents =()=> {
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})

const [profile, setProfile]=useState({
  student:[],
  studentClass:[],
  isDisplay:false,
  studentCode:'',
  classArm:'Secondary',
  documentName:''

});

let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const student  = useSelector(state => state.rootReducer.students);
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const [studentList, setStudentList]= useState([])  
    const [errors, setErrors] = useState({});
    const [allDocument, setAllDocument]= useState([])  
    const [documentFile, setDocumentFile] = useState('')

const handleSearch =(event)=>{
  event.preventDefault(); 
        
        let errors = {};   
        if(profile.studentClass.length===0){
            errors.studentClass ='Please select class';
        }  

        if(profile.student.length===0){
            errors.student ='Please select student';
        } 

        setErrors(errors)

        if(Object.keys(errors).length === 0){
          const head = getResultHead(profile.studentClass.classtype)!=='Secondary'?'Primary':'Secondary'
          setProfile({...profile, studentCode:profile.student.value, isDisplay:true, classArm:head})
            }
}




const  handleReadFileImage = function(fieldName){
  return function(newValue){                 
      const value = newValue.target.files[0]
    // setImage(value)
    readFileImage(newValue);
 }
 
}


const readFileImage = (input)=>{
   
  let displayMessage = '';
  const PreviewImage = input.target.name;
      let newImage = "viewPassport";
      var fileName = document.getElementById(PreviewImage).value;
  var idxDot = fileName.lastIndexOf(".") + 1;
  var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

  if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" ||  extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="pdf" || extFile==="PDF" || extFile==="docx" || extFile==="DOCX" ){
      //TO DO    
      
       var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
  if (FileSize > 3) {
      displayMessage = PreviewImage +': File uploaded is more than maximum size Allow. it exceeds 3Mb';
      document.getElementById(PreviewImage).value = '';
      setDocumentFile('')

  } else {
      if (input.target.files && input.target.files[0]) { //Check if input has files.
          var reader = new FileReader();//Initialize FileReader.
          reader.onload = function (e) {
            setDocumentFile(input.target.files[0])
            setErrors({...errors, documentFile:''})
          }
          reader.readAsDataURL(input.target.files[0]);
      }
  }
}else{
  displayMessage = 'Only gif|jpg|png|jpeg|pdf  files are allowed with maximum of 3Mb'
 document.getElementById(PreviewImage).value = '';
 setDocumentFile('')
 
}   

if(displayMessage.length > 0){
  Alerts('Error!', 'danger', displayMessage)
}
  
} 



const handleSubmitDocument = event =>{
  event.preventDefault();

  let errors = {};     
 
  if(!profile.documentName){
      errors.documentName ='Please select document type';
  }
  
  if(documentFile.length===0){
      errors.documentFile ='Please upload document';
  }


setErrors(errors)

if(Object.keys(errors).length === 0){
  submitDocument()
  }

}

  const submitDocument= async()=>{
    document.getElementById('closeSchool').click()

      setNotice({...notice,  isLoading: true}) 

          const fd = new FormData();
          fd.append("jwt", Token);
      
    var fileUrl = 'sd'+Math.random().toString(36).substr(2,9)+documentFile.name
      fd.append("image", documentFile);
      fd.append('fileUrl', fileUrl);
      fd.append('documentName', profile.documentName);
      let url = ServerUrl+'/update_controller/tbl_school_documents';
      await axios.post(url, fd, config)
      .then(response =>{
          if (response.data.type ==='info'){
              Alerts('Saved!', 'info', response.data.message)
          } else{
              setErrors({...errors, errorMessage: JSON.stringify(response.data)})
      }  
      })
      .catch((error)=>{
          setErrors({...errors, errorMessage: JSON.stringify(error.message)}) 
      })
      .finally(()=>{
        setNotice({...notice, isLoading: false}) 
      setDocumentFile('')
      setProfile({...profile, documentName:''})
      document.getElementById('documentFile').value = ''
          window.location.reload()
  })
}


const getLink =(code, link)=>{

const linkHandler ={
  defe: ImagesUrl+'/students/documents/'+link,
  de2fe:ImagesUrl+'/students/documents/'+link,
  de3fe:ImagesUrl+'/students/documents/'+link,
  de34e:ImagesUrl+'/students/documents/'+link,
  de54e:ImagesUrl+'/students/documents/'+link,
  de56e:'#!',
  de576e:ServerUrl+'/printout/admission_letter/'+profile.classArm,
  gdtd:'#!',
  gdt9d:ServerUrl+'/printout/student_profile.php?studentCode='+profile.studentCode+'&token='+userToken,
  gdt10d:'#!'
}
return linkHandler[code];
}


const fetchSchoolDocument =()=>{
  const fd = new FormData(); 
      fd.append("jwt", Token);
  let url = ServerUrl + '/fetch_controller/tbl_school_documents'
  axios.post(url, fd, config)
  .then(response =>{
    
    const option  = response.data
    const list  = []

    for (var i = 0; i< option.length;  i++) {
      list.push(
          {  
              code:option[i].code,
              link:getLink(option[i].code, option[i].file_url),
              file_url:option[i].file_url,
             type:option[i].document_type,
              order:option[i].order_by,
          });
          }
    setAllDocument(list)
  })
}

              const background = ['bg-simple-c-blue', 'bg-simple-c-green', 'bg-simple-c-pink', 'bg-simple-c-yellow', 'bg-c-blue', 'bg-c-green', 'bg-c-pink', 'bg-c-yellow', 'bg-simple-c-blue', 'bg-simple-c-green', 'bg-simple-c-pink', 'bg-simple-c-yellow', 'bg-c-blue', 'bg-c-green', 'bg-c-pink', 'bg-c-yellow'];

              const textColor = ['btn-success', 'btn-primary', 'btn-dark', 'btn-danger', 'btn-warning', 'btn-info', 'btn-inverse', 'btn-success', 'btn-primary', 'btn-dark', 'btn-danger', 'btn-warning', 'btn-info', 'btn-inverse'];


              const handleSelect = (option, action)=>{
               
                if(action.name==='studentClass'){
                  setStudentList(student.filter(item=>item.sectionID===option.value))
                  setProfile({...profile, [action.name]: option, student:[], isDisplay:false});
                }else{
                  setProfile({...profile, [action.name]: option});
                }

                setErrors({...errors, [action.name]:''})
               }

 
const handleChange = (e)=>{
  const {name, value} = e.target;
  setProfile({...profile, [name]: value});
  setErrors({...errors, [name]:''})
}





useEffect(()=>{   
  fetchSchoolDocument();
},[profile]);


useEffect(()=>{
  setIsAuthenticated(menuLink.includes(location.pathname))

},[]);

        return (  
            
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
   {isAuthenticated?    <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title='Student Document'>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">



      <div className="modal fade " id='uploadProfile' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-md" role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Upload Document</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">

			  <div className="row">

        <section className="col-md-12">
    <div className="form-group">
    
    <label >Document Type</label>
<select value={profile.documentName} name='documentName' onChange={handleChange} className={errors.documentName ? 'form-control form-control-danger' : 'form-control'}>

<option value="" disabled > Select Type</option>
<option value="Handbook" >Handbook</option>
<option value="Child List - Girls" >Child List - Girls</option>
<option value="Child List - Boys" >Child List - Boys</option>
<option value="Termly Calendar" >Termly Calendar</option>
<option value="Admission Letter" >Admission Letter</option>
<option value="Policy Document" >Policy Document</option>
<option value="Clearance Letter" >Clearance Letter</option>

</select> 
   
		<span style={{color:'red'}}>{errors.documentName && errors.documentName}</span>  
                </div>
        </section>


        <section className="col-md-12">
                      <div className="form-group">
							<label  >Choose Document <span style={{color:'red'}}>*</span></label>
                   
                    <input id="documentFile"  onChange={handleReadFileImage('documentFile')} className={errors.documentFile  ? 'form-control form-control-danger' : 'form-control'}  name="documentFile" type="file"  /> 
                    <span style={{color:'red'}}>{errors.documentFile && errors.documentFile }</span>
                   
									</div>
         </section>


				</div>
				
              </div>
              <div className="modal-footer">
   <button type="button"  id='closeSchool' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-inverse" onClick={handleSubmitDocument}>Upload</button>
              </div>
          </div>
      </div>
  </div>


                
                <div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Select Student</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
        <div className="row">
       

      <section className="col-md-6">
                       <div className="form-group">
				<label > Search By  Class </label>
				<Select  options={
                            schoolClass&&schoolClass.map((list, idx)=> {
                              return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                  return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                              }) }
                            })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
           onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={profile.studentClass}  /> 
                        <span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
										
									</div> </section> 



         <section className="col-md-6">
    
    <div className="form-group">
    
    <label >Select Student</label>
    <Select  options={studentList&&studentList.map((list, idx)=> {
                                   return {key:idx, value: list.code, label:list.studentName, admissionNumber:list.admissionNumber }
                                 })
                            }    
                getOptionLabel={option =>option.label +' '+ option.admissionNumber} 
                className={errors.student ? 'form-control form-control-danger' : ''}   
    onChange={handleSelect}  name="student" value={profile.student}  />   
                   <span style={{color:'red'}}>{errors.student && errors.student}</span></div>
  </section>

									</div>
										
                <div className="row">
                <section className="col-md-12 justify-content-center d-flex">

                

<button type="button"  className="btn btn-danger  mr-2" onClick={handleSearch}><i className="fa fa-search"></i> Search Record</button>

<button type='button' data-toggle="modal" className='btn btn-inverse'  data-target='#uploadProfile' ><i className="feather icon-arrow-up"></i> Upload Documents</button> 
</section>	                </div>
							</div>
							</div>

							</div>
                </div>



   {profile.isDisplay&&<div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> DOCUMENT LIST</strong>
				</div>
             <div className="card-body">
           

<div className="row">
{allDocument.map((item, index)=><div className="col-xl-6 col-md-12" key={index}>
        <form  method='POST' action={item.link} target='_blank' >

        <input type='hidden' name='studentCode' value ={profile.studentCode} />

        <input type='hidden' name='jwtToken' value ={Token} />
        <input type='hidden' name='classArm' value ={profile.classArm} />


              <div className= {`card social-card ${background[index]}`}>
                  <div className="card-block">
                      <div className="row align-items-center">
                          <div className="col-auto">
                              <i className={`feather icon-file-text f-34 social-icon text-c-blue`}></i>
                          </div>
                          <div className="col">
                              <h4 className="m-b-0">{item.type}</h4>    
                      <button type='submit' className={`btn btn-inverse btn-sm btn-round mt-1 `}><i className="feather icon-arrow-down"></i> Preview</button>
                          </div>
                      </div>
                  </div>
                  {/* <a href={item.link} className="download-icon" ><i className="feather icon-arrow-down"></i></a> */}
              </div>
              </form>
          </div>)}


          </div>



             </div>
          </div>
        </div></div>}





   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Documents) 