import React, {useState, useEffect} from 'react'
import {Alerts} from '../component/notify'
import {ServerUrl, ImagesUrl, config, Token, KAYUS_URL, PortalType} from '../component/include'
import axios from 'axios'
import Cookies from 'js-cookie'
import {encrypt} from '../component/authentication'

 const CentraLogin =()=> {

	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const [user, setUser] = useState({
		email:'',
		password:'',
		remember:false,
		type:'Staff',
		title:'Staff Login',
		schoolInfo:[],
		date:new Date().toISOString().slice(0,10),
		dateTime:new Date().toISOString().slice(0,19)
    })
    const [errors, setErrors] = useState({});

    const handleChange = event =>{		
        let {name, value} = event.target;	
        if(name==='remember'){
            value = !user.remember
        }	
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }
    
	
		
    
	function submitAdmin(event){  
		event.preventDefault();

		if(navigator.onLine){
		setNotice({...notice, isLoading: true}) 	
           
        let formData = user;
        const fd = new FormData();
        for(let k in formData){
          fd.append(k, formData[k])
        }
           let url = ServerUrl+'/login_controller.php?tablename=tbl_login_user';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){
			
			//1000 * 60 * SESSION_IDEL_MINUTES
			var inSixtyMinutes = Date.now() + 419 * 60 * 1000;
			var inOneMinutes = Date.now() + 418 * 60 * 1000;
			  var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
			  
			  	localStorage.setItem('_kayus_expt', inSixtyMinutes)
				localStorage.setItem('_kayus_bexpt', inOneMinutes)

				Cookies.set('tkrspw', response.data.reset_password, {expires: inOneHours })

				Cookies.set('kayus_code', response.data.code, {expires: inOneHours })
				Cookies.set('kayus_token', response.data.jwt,  {expires: inOneHours })
		   
				Cookies.set('portal', user.type, {expires: inOneHours })
				
				Cookies.set('kayus_ed', response.data.allowEdit, {expires: inOneHours })
				Cookies.set('kayus_dt', response.data.allowDelete,  {expires: inOneHours })
				Cookies.set('clkysd', response.data.classID,  {expires: inOneHours })
				Cookies.set('sckayd', response.data.sectionID,  {expires: inOneHours })

			 	
				if(user.type==='Staff'){
					localStorage.setItem('kayus_link', [])
				}else{
					localStorage.setItem('kayus_link', encrypt(response.data.menuLink))
				} 
			
			Alerts('Welcome!', 'success', response.data.message)
			
			var queryString = new URLSearchParams(window.location.search).get("refferer")

		

			if(response.data.reset_password ==='Yes'){

				if(user.type==='Admin'){
				window.open('/staff/my_password', '_self')
				}else{
					window.open('/staffportal/staff/change_password', '_self')
				}
			}else{
				if(queryString!==null){
					window.open(queryString, '_self')
				  }else{
					if(user.type==='Admin'){
						window.open('/startup', '_self')
					}else{
						window.open('/staffportal/dashboard', '_self')
					}
					
				  } 
			} 

			}else if (response.data.type ==='error'){
				Alerts('Error!', 'info', response.data.message)
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'info', error.message)
			 
		}).finally(()=>{
            setNotice({...notice, 
				isLoading: false
			}) 
        })   
		
	}else{
		Alerts('Error!', 'info', 'No internet connection')
	}
 }



 const fetchSchoolInfo = async ()=>{

	let sql = 'SELECT banner, schoolLogo FROM tbl_basic_details';

	const fd = new FormData();
	fd.append('sql', sql)
	let url = ServerUrl+'/fetch_CarelesSql_controller'
		const {data} = await axios.post(url, fd, config)
		
		setUser({...user, schoolInfo:data[0]})
}


useEffect(()=>{  
	fetchSchoolInfo()
	if(Token!==undefined){
		if(PortalType==='Admin'){
			window.open('/dashboard', '_self')
		}else{
			window.open('/staffportal/dashboard', '_self')
		}
		
		 }  
},[]);


        return (<>
		
		
<div className="" style={{backgroundColor:'white', height: '100vh'}}>

<div className="row">
	

	<div className="col-md-6 p-4">

<div className="row  justify-content-center ">
<div className="col-md-7">



<div className=" m-b-20 mt-20 ">
			  <div className="col-md-12 ">
			  <div className="text-center">
	  <img src={ImagesUrl+'/about/'+user.schoolInfo.schoolLogo} className="img-100" alt="logo.png" />
	  </div>
				  <h3 className="text-center" >{user.title} </h3>
			  </div>
		  </div>


		  <form action="#" method="post" role={'presentation'} onSubmit={submitAdmin}>


		  <div className="form-group form-primary">

<input type="email" id="email" name="email" required autoComplete='off' role={'presentation'} value={user.email} placeholder="Your Email Address" onChange={handleChange} className={errors.email  ? 'form-control form-control-lg formerror' : 'form-control form-control-lg'} /> 
<span className=" form-bar error">{ errors.email}</span>

</div>
<div className="form-group form-primary mb-4">
<input type="password" id="password" name="password" required autoComplete='off' role={'presentation'} value={user.password} placeholder="Password" onChange={handleChange} className={errors.password  ? 'form-control form-control-lg formerror' : 'form-control form-control-lg'} /> 
<span className=" form-bar error">{errors.password }</span>


</div>



<div className="row m-t-25 mb-4 text-left">
			  <div className="col-12">
				  <div className="checkbox-fade fade-in-primary d-">
					  <label>
						  <input type="checkbox" checked={user.remember} id="remember" name="remember" onChange={handleChange} />
						  <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-primary"></i></span>
						  <span className="text-inverse">Remember me</span>
					  </label>
				  </div>
				  <div className="forgot-phone text-right f-right">
					  <a href="/forgot_password" className="text-right f-w-600"> Forgot Password?</a>
				  </div>
			  </div>
		  </div>

		  {notice.isLoading ?	<button type="button" disabled className="btn btn-block btn-primary"><i className='fa fa-refresh fa-spin'></i> Please wait ... </button>:
<button type="submit" className="btn btn-block btn-lg btn-primary" >Login as {user.type} </button>}

<span className="d-block text-center my-4  text-bold">&mdash; OR &mdash;</span>
<div className="social-login">

	



<a href="#!" onClick={()=>setUser({...user, title:'Staff Login', type:'staff'})} className=" btn  btn-success ">
<span className="fa fa-user mr-3"></span> STAFF LOGIN
</a>


<a href="#!" onClick={()=>setUser({...user, title:'Administrator Login', type:'Admin'})}  className=" btn  btn-danger ">
<span className="fa fa-user mr-3"></span> ADMINISTRATOR LOGIN
</a>


<a href="#!" target='_blank' className="facebook btn ">
<span className="fa fa-shopping-basket mr-3"></span> TUCKSHOP LOGIN
</a>

</div>
</form>
<hr/>
		  <div className="row">
			  <div className="col-md-10">
				  <p className="text-inverse text-left m-b-0">Thank you.</p>
				  <p className="text-inverse text-left"><a href={KAYUS_URL} target={'_blank'}><b className="f-w-600">Powered By KAYUS</b></a></p>
			  </div>
			  <div className="col-md-2">
			  <a href={KAYUS_URL} target={'_blank'}>
			  <img src={ImagesUrl+'/about/kayus.png'} alt="KAYUS" className="img-30" />
			  </a>
			  </div>
		  </div>
		  <hr/>
</div>


</div>
</div>


<div className="col-md-6 img-bg" style={{backgroundImage: `url(${ImagesUrl}/about/${user.schoolInfo.banner})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', height: '100vh'}}>

	
	</div>


</div>
</div>	
</>
 );
        
}

export default React.memo(CentraLogin)  