import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, FormatNumber, Currency,  config, allowDelete, allowEdit} from '../component/include'
import Select from 'react-select'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'

const SalaryPayment =()=> {

    const year = new Date().getFullYear()
    const [attendnace, setAttendance] = useState([])
    
    const [staff, setStaff]= useState([])
    const [paymentType, setPaymentType]= useState([])
    const [account, setAccount] = useState([])
    const [staffLeave, setStaffLeave]=useState([])
    const [newLeave, setNewLeave]=useState([])
    const [leave, setLeave]=useState([])
    const [errors, setErrors] = useState({});
    const [approvedLeave, setApprovedLeave]= useState([])

    const [previousPayment, setPreviousPayment]=useState([])
    const [salaryTitle, setSalaryTitle]=useState([]);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const [newSalary, setNewSalary]=useState([])
    const [staffSalary, setStaffSalary]=useState([])
    const [paymentList, setPaymentList]=useState([])
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const [payment, setPayment] = useState({
        staff:[],
        paymentType:[],
        payMonth:'',
        payDate:'',
        account:[],
        remarks:''
    })

    const [CurrentSession, setCurrentSession] = useState('')
    const  handleSession =()=>{
    
        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        } 
    
        
    const getDaysBetween =(value)=>{

        const days =  new Date(value.slice(0,4), value.slice(5,7), 0).getDate();
        const from = value+'-01';
        const to = value+'-'+days;
        
        const start = new Date(from)
        const end =  new Date(to)
    
        var result = []
        while (start <= end){
            result.push(new Date(start).toISOString().slice(0,10))
            start.setDate(start.getDate()+1)
        }

       
        let final =   'SELECT s.staffName, s.fileNo, sum(case when a.attendance  = "P" then 1 else 0 end) as P, sum(case when a.attendance  = "A" then 1 else 0 end) as A, sum(case when a.attendance  = "H" then 1 else 0 end) as H  from tbl_staff s, tbl_staff_attendance a where   s.code = a.staffCode and a.attendanceDate >= "'+from+'" and a.attendanceDate <= "'+to+'" and a.staffCode = "'+payment.staff.value+'" group by a.staffCode '


        const fd = new FormData();
        fd.append('jwt', Token); 
        fd.append("sql", final);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>{
            if(result.data.length!==0){
                setAttendance(result.data)
            } 
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)

    })
}



const fetchStaff =()=>{

    let sql ="Select code, staffName, fileNo, position, department from tbl_staff WHERE employmentStatus ='Working' ";

    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
        setStaff(result.data)
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
}


    const  fetchPaymentType =()=>{  
        const fd = new FormData();
        fd.append('jwt', Token); 
        let url = ServerUrl+'/fetch_controller/tbl_payment_type'
        axios.post(url, fd, config).then(result=>setPaymentType(result.data))
    
     } 

     const fetchAccount=()=>{
        var sql =  'select  a.accountName, a.code,   (coalesce(a.openingBalance,0) - coalesce((select sum(e.amount) from tbl_expenses e where e.account = a.code  group by e.account),0) + coalesce((select sum(i.amount) from tbl_income i where i.account = a.code  group by i.account),0) -  coalesce((select sum(s.totalAmount) from tbl_salary_payment  s where s.paymentAccount = a.code group by s.paymentAccount),0)) as openingBalance from tbl_account a'
          
          setNotice({...notice, isLoading: true}) 
          const fd = new FormData();
          fd.append('jwt', Token); 
          fd.append("sql", sql);
          let url = ServerUrl+'/fetchBySql_controller'
          axios.post(url, fd, config).then(result=> setAccount(result.data))
          .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
          setNotice({...notice, isDataFetching: false})
  
          })  
      }

     const fetchStaffLeave =(staff)=>{
        const fd = new FormData();
        fd.append('jwt', Token); 
        let url = ServerUrl + '/fetchField/tbl_salary_structure/leaveStructure/staff/'+staff
        axios.post(url, fd, config).then(result=>setStaffLeave(result.data))
            
    }
    
    const fetchLeave =()=>{
        const fd = new FormData();
        fd.append('jwt', Token); 
        let url = ServerUrl+'/fetch_controller/tbl_staff_leave_settings'
        axios.post(url, fd, config).then(result=>setLeave(result.data))
    
    }

    const fetchSalaryTitle =()=>{
        const fd = new FormData();
        fd.append('jwt', Token); 
        let url = ServerUrl+'/fetch_controller/tbl_salary_title'
        axios.post(url, fd, config).then(result=>setSalaryTitle(result.data))
    
    }
    



const handleChange=(event)=>{        
    const {name, value}=event.target
    if(name==='payMonth'){
        setPayment({...payment, [name]:value, payDate:''});
        fetchPayment(value)
        getDaysBetween(value)
        setNewLeave('')  
        setNewSalary('') 
        setPreviousPayment('')
    }else if(name==='payDate'){
        setPayment({...payment, [name]:value})
        combineLeave()
    }else{
        setPayment({...payment, [name]:value})
    }

    setErrors({...errors, [name]:'', structure:''})
}


const fetchStaffSalary =(staff)=>{
    const fd = new FormData();
    fd.append('jwt', Token); 
    let url = ServerUrl + '/fetchField/tbl_salary_structure/salary/staff/'+staff
    axios.post(url, fd, config).then(result=>setStaffSalary(result.data))
        
}

const  fetchApprovedLeave =(staff)=>{  
    
    const fd = new FormData();
    fd.append('jwt', Token); 
let sql = "Select leaveType, SUM(days) as total from tbl_staff_leave where status ='Approved' and staff ='"+staff+"' and sessionYear ='"+CurrentSession+"' group by leaveType"

   let url = ServerUrl+'/fetchBySql_controller'
   fd.append("sql", sql)
   axios.post(url, fd, config).then(result =>setApprovedLeave(result.data)) 
} 

const fetchPayment =(payMonth)=>{
    const fd = new FormData();
          fd.append('jwt', Token); 
   let sql = "Select salaryStructure, ID, code from tbl_salary_payment where staff ='"+payment.staff.value+"' and  paymentMonth = '"+payMonth+"'"

       let url = ServerUrl+'/fetchBySql_controller'
       fd.append("sql", sql)
       axios.post(url, fd, config).then(result =>setPreviousPayment(result.data)) 
       
}

const  fetchPaymentList =(staff)=>{  
    const fd = new FormData();
          fd.append('jwt', Token); 
    
let sql = "Select s.ID, s.code,   s.paymentMonth, s.paymentDate, s.totalAmount, p.paymentType, t.accountName from tbl_salary_payment s, tbl_payment_type p, tbl_account t  where s.paymentAccount = t.code and s.paymentType = p.code and s.staff ='"+staff+"' order by s.ID DESC"

   let url = ServerUrl+'/fetchBySql_controller'
 
   fd.append("sql", sql)
   axios.post(url, fd, config).then(result =>{
     setPaymentList(result.data)
   }) 

}  

const getLeave = (ID, field) =>{
    const result = leave.filter(list=>list.ID===ID);
   const answer = result.map((c)=>c[field]); 
     return  answer;
   }
   const getApprovedTotal = (ID) =>{
    const result = approvedLeave.filter(list=>list.leaveType===ID);
   const answer = result.map((c)=>c.total); 
     return  String(answer)!==''?String(answer):'0';
   }

const combineLeave=()=>{

   let value = []
   staffLeave&&staffLeave.map((itm, idx)=>{
        value.push({ID:itm.ID, leaveName:getLeave(itm.ID, 'leaveName'), leaveType:getLeave(itm.ID, 'leaveType'), timeCycle:getLeave(itm.ID, 'timeCycle'), days:itm.days, approve:getApprovedTotal(itm.ID), key:idx});
        return ''
    } );
    
    setNewLeave(value)
    combineSalary();
}



const combineSalary=()=>{
   let value = []
   staffSalary&&staffSalary.map((itm, idx)=>{
        value.push({code:itm.code, title:itm.title, amount:itm.amount, toPay:itm.amount, due:'Due', paid:'0', balance:'0', salaryHead:itm.salaryHead, salaryCode:itm.salaryCode, key:idx});
        return ''
    } );
    
    if(previousPayment.length!==0){
    const rs =previousPayment.map((c)=>c.salaryStructure)
    setNewSalary(JSON.parse(rs))
    }else{
    setNewSalary(value)
    }

    
}
const handleSelect= (option, action)=>{
    if(action.name==='staff'){
        
        fetchApprovedLeave(option.value) 
        fetchStaffLeave(option.value) 
        fetchStaffSalary(option.value) 
        fetchPaymentList(option.value)
        setNewLeave([])  
        setNewSalary([]) 
        setPreviousPayment([])
        setAttendance('')
        setPayment({...payment, [action.name]:option, payMonth:'', payDate:''});    
    }else{

        setPayment({...payment, [action.name]:option});
    }
   
    setErrors({...errors, [action.name]:'', structure:''})
   
}

const handleChangeSalary = (e, index, amount)=>{
    /* if(Number(e.target.value)>amount){
        alert('Amount to pay cannot be more than Amount')
    }else{ */
    const std =JSON.parse(JSON.stringify(newSalary))   
      let  value =e.target.value;


      value = value.replace(/[^0-9]/g, '');

       std[index][e.target.name] = value;
       std[index].balance = Number(amount)- Number(value);
       setNewSalary(std); 
       
//}

}

const getTotalAmountToPay =(salary)=>{
    var amount =0
    salary.map((sl)=>
    sl.salaryHead==='EARNING'?
        amount += Number(sl.toPay):amount -=Number(sl.toPay)
    )
    return amount
}
const getTotalBalance =(salary)=>{
    var amount =0
    salary.map(sl=>
    sl.salaryHead==='EARNING'?
        amount += Number(sl.balance):amount -=Number(sl.balance)
    )
    return amount
}
   const getTotalAmount =(salary)=>{
    var amount =0
    salary.map(sl=>
    sl.salaryHead==='EARNING'?
        amount += Number(sl.amount):amount -=Number(sl.amount)
    )
    return amount
}


    const handleBack =()=>{
        setPayment({
            staff:[],
            paymentType:[],
            payMonth:'',
            payDate:'',
            account:[],
            remarks:''
        })
            setNewLeave([])  
            setNewSalary([]) 
            setPreviousPayment([])
            setPaymentList([])
            setAttendance('')
    } 



    const handleDelete = (code, table)=>{  
        setNotice({...notice, 
           isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
              const fd = new FormData();
              fd.append('jwt', Token); 
           fd.append('ID', 'code')
            fd.append('data', code)
            let url = ServerUrl+'/delete_controller/'+table
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
               window.location.reload()
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
            fetchPaymentType()
            fetchLeave()
               setNotice({...notice, 
                   isLoading: false
               })
           }) 
    }

   





    const tableHeader = [
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
            
            {allowDelete==='Yes'? <a href="#!" data-toggle="modal" className="btn btn-sm btn-danger btn-sm"  data-target={'#warningdialog-'+cell}     title="Revert Salary Payment"><i className="fa fa-mail-reply"></i></a>:[]}

        <WarningModal message="This will revert all salary payment for the month. Are you really sure?" handleAction={()=>handleDelete(cell, 'tbl_salary_payment')} mID={cell} /> 
        </div>, editable: false},
        {dataField: 'totalAmount', text: 'Amount', formatter:(cell)=>Currency+FormatNumber(cell),editable:false },
        {dataField: 'paymentType', text: 'Payment Mode', editable:false},
        {dataField: 'paymentMonth', text: 'Month',  editable:false},       
        {dataField: 'paymentDate', text: 'Date Paid', editable:false, formatter:(cell)=>longDate(cell)},
          {dataField: 'accountName', text: 'Account',editable:false}, 
     ];


    
 const TableBasic=(props)=>{
       
    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData();
        fd.append('jwt', Token); 
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
         
           let url = ServerUrl+'/updateCustom_controller/'+props.table
          axios.post(url, fd, config)
          //.then(result => console.log(result.data))
          .then()
          .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        }  
        
        const { SearchBar } = Search;
        
        const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
        const options = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
        { text: 'All', value: props.data.length }]
        
        };
        return  <ToolkitProvider search columnToggle 
                    keyField='code' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            
                            <div className="form-group">
                                <SearchBar
                                { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             <div className="dt-buttons btn-group pull-right"> 


                            
    <form method='POST' action={ServerUrl+'/printout/staff_salary_payment.php?token='+userToken} target='_blank'>
                <input type='hidden' name='staffCode' value ={payment.staff.value} />

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-outline-primary" > <i className="fa fa-print"></i>Print Preview</button>
        
        </form>

</div>        
                                           
                    <BootstrapTable
                                { ...props.baseProps }
                                            striped
                                        hover
                                        condensed
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                    cellEdit={ cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    autoSelectText: true,
                            beforeSaveCell: (oldValue, newValue, row, column) => {
                            if(oldValue !==newValue){
                            handleUpdate(column, newValue, row.ID);
                            
                            return true;
                            }
                            
                        }
                                    }) }
                                />
                            </div>
                            )
                        }
    </ToolkitProvider>
    }




  

   
 
    const handleSubmit = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(payment));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   
        if(values.staff.length===0){
            errors.staff ='Please select staff';
        } 
        if(values.paymentType.length===0){
            errors.paymentType ='Please select payment type';
        } 
        if(values.account.length===0){
            errors.account ='Please select payment account';
        } 
        if(newSalary.length===0){
            errors.structure ='Salary structure not found';
        } 
        if(!values.payMonth){
            errors.payMonth ='Please select payment Month';
        } 
        if(!values.payDate){
            errors.payDate ='Please select payment Date';
        } 
        if(Number(getTotalAmountToPay(newSalary)) > Number(values.account.totalRemain) ){

            errors.account ='You have insufficient balance in the selected account';
            window.scrollTo(0,0);
        } 
        
        return errors;
        }

        
      
useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])

    const  submit=()=>{  
              
    setNotice({...notice, 
        isLoading: true})   
        var options = newSalary;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
          value.push({code:options[i].code, title:options[i].title, amount:options[i].amount, toPay:options[i].toPay, due:options[i].amount===options[i].toPay?'No Dues':'Due', paid:options[i].toPay, balance:options[i].balance, salaryHead:options[i].salaryHead});
        }

        const fd = new FormData();
        fd.append('jwt', Token);  							
        fd.append('staff', payment.staff.value);
        fd.append('paymentType', payment.paymentType.value); 
        fd.append('paymentAccount', payment.account.value);
        fd.append('paymentMonth', payment.payMonth); 
        fd.append('paymentDate', payment.payDate);
        fd.append('remarks', payment.remarks); 
        fd.append('totalAmount', Number(getTotalAmountToPay(newSalary)));
        fd.append('salaryStructure', JSON.stringify(value, null, 2)); 
        if(previousPayment.length!==0){
            fd.append('code', previousPayment.map((c)=>c.code));   
        }
         let url = ServerUrl+'/save_controller/tbl_salary_payment'
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
                    }else if(response.data.type ==='info'){
                        Alerts('Update', 'default', response.data.message)
                            } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
              Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
        setIsSubmitting(false)
        handleBack()
        fetchAccount()
        setNotice({...notice, 
            isLoading: false})
            })
        
     }    




useEffect(()=>{
    fetchStaff()
    fetchPaymentType();
fetchAccount()
fetchLeave()
fetchSalaryTitle()
handleSession()
},[]);



        return (  
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Salary Payment">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Payroll</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Salary Payment</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div  className="row  ">   
<div className="col-sm-12">                                                                  
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Staff Salary Payment List</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
     
      <section className="col-md-6">
   <div className="form-group">
<label >Select Staff <span style={{color:'red'}}>*</span></label>
      <Select  options={
                            staff.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.fileNo, staffName:list.staffName}
                             })} 
                             getOptionLabel={option =>` ${option.staffName} - ${option.label} `}
onChange={handleSelect} className={errors.staff ? 'form-control form-control-danger' : ''} name="staff" value={payment.staff}  /> <span style={{color:'red'}}>{errors.staff && errors.staff}</span>
   </div>

</section>

<section className="col-md-6">
   <div className="form-group">
<label >Paymnet Type <span style={{color:'red'}}>*</span></label>
      <Select  options={
                            paymentType.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.paymentType}
                             })} 
onChange={handleSelect} className={errors.paymentType ? 'form-control form-control-danger' : ''} name="paymentType" value={payment.paymentType}  /> <span style={{color:'red'}}>{errors.paymentType && errors.paymentType}</span>
   </div>

</section>

<section className="col-md-6">
<div className="form-group">
						<label >Payment Account <span style={{color:'red'}}>*</span> </label>
						<Select options={
                            account&&account.map((list, idx)=> {
                                return {key:idx, value: list.code, label: list.accountName, balance:list.openingBalance, totalRemain:list.openingBalance}
                             })} 
                             getOptionLabel={option =>` ${option.label} Balance: ${Currency+FormatNumber(option.balance)}`} 
onChange={handleSelect} className={errors.account ? 'form-control form-control-danger' : ''} name="account" value={payment.account}  /> <span style={{color:'red'}}>{errors.account && errors.account}</span>
										
											</div>

   <div className="form-group">
						<label >Remarks</label>
		<input type="text" name="remarks" value={payment.remarks} onChange={handleChange} className='form-control' /> 
			</div>
</section>
<section className="col-md-6">
   

   <div className="form-group">
<label >Payment Month <span style={{color:'red'}}>*</span></label>
<input type="month" className={errors.payMonth ? 'form-control form-control-danger' : 'form-control'} name="payMonth"  onChange={handleChange} value={payment.payMonth} />
<span style={{color:'red'}}>{errors.payMonth && errors.payMonth}</span>
   </div>


   <div className="form-group">
<label >Payment Date <span style={{color:'red'}}>*</span></label>
<input type="date" className={errors.payDate ? 'form-control form-control-danger' : 'form-control'} name="payDate"  onChange={handleChange} value={payment.payDate} />
<span style={{color:'red'}}>{errors.payDate && errors.payDate}</span>
   </div>
</section>


				</div>	
{errors.structure?<div className="alert alert-danger background-danger">{errors.structure}</div>:''}

            {attendnace&&attendnace.length!==0?    <div className="row" >
                
<section className="col-md-12"><label style={{color:'green'}}><b>Attendance (Total = {Number(attendnace[0].P)+ Number(attendnace[0].H)+Number(attendnace[0].A)})</b></label>
<table className="table">
    <tbody> 
        <tr>
            <td><span className="pcoded-badge label label-success">Present : {attendnace[0].P}</span></td>
                <td><span className="pcoded-badge label label-info">Half Day : {attendnace[0].H}</span></td>
                <td><span className="pcoded-badge label label-danger">Absent : {attendnace[0].A}</span></td>
            </tr>
               </tbody>
               </table>


</section></div>:''}

{newLeave.length!==0? 
<div className="row" >
                
    <section className="col-md-12"><label style={{color:'green'}}><b>Leave Structure</b></label>
                <table className="table">
                <thead>
                 <tr>
                 <th>Leave Type</th>
                 <th>Time Circle</th>
                 <th>Type</th>
                 <th>Assign Leaves</th>
                 <th>Approved</th>
                 </tr>
             </thead>
                    <tbody> 
                        {newLeave&&newLeave.map((lv, id)=>
                        <tr key={id}>
                           <td>{lv.leaveName}</td>
                        <td>{lv.timeCycle}</td>
                        <td>{lv.leaveType}</td>
                        <td>{lv.days}</td>
                        <td>{lv.approve}</td>
                        </tr>)}
                        </tbody>
                </table>
                </section></div>:''}
<br/>
{ newSalary.length!==0? 
                <div className="row" >
                
                <section className="col-md-12"><label style={{color:'green'}}><b>Salary Structure</b></label>
                            <table className="table">
                            <thead>
                             <tr>
                             <th>Salary Head</th>
                             <th>Amount</th>
                             <th>Amount To Pay</th>
                             <th>Due</th>
                             <th>Paid</th>
                          {/*    <th>Balance </th> */}
                             </tr>
                         </thead>
                                <tbody> 
                                    {newSalary&&newSalary.map((salary, id)=>
                                    <tr key={id} style={{color:salary.salaryHead==='EARNING'?'green':'red'}}>
                                       <td>{salary.title}</td>
                                       <td>{Currency+FormatNumber(salary.amount)}</td>
                <td><input  type="text" min='0' onFocus={(e)=>e.target.select()} className='form-control' name="toPay"  onChange={(e)=>handleChangeSalary(e, id, salary.amount)} value={salary.toPay} /></td>
                                    <td>{salary.due}</td>
                                    <td>{Currency+FormatNumber(salary.paid)}</td>
                                 {/*    <td>{Currency+FormatNumber(salary.balance)}</td> */}
                                    </tr>)} 
                                    <tr>
                                       <td><b>Total</b></td>
                                    <td>{Currency+FormatNumber(getTotalAmount(newSalary))}</td>
                                       <td>{Currency+FormatNumber(getTotalAmountToPay(newSalary))}</td>
                                       <td>-</td>
                             <td>0</td>                                       
                                     {/*   <td>{Currency+FormatNumber(getTotalBalance(newSalary))}</td> */}
                                    </tr>
                                    </tbody>
                            </table>
                            
                            
                            </section></div>:''}

                            
<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" onClick={()=>handleBack()} className="btn btn-inverse "><i className="fa fa-refresh"></i> Reset</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={handleSubmit}  className={previousPayment.length!==0?"btn btn-success ":'btn btn-primary'}><i className="fa fa-save"></i>{previousPayment.length!==0?'Update Payment':'Make Payment'}</button>
								</footer>		</section>		
							</div>
                            
                            
                            </div> </div> 
							
</div>
</div> </div>    </div>
                            
                            </div>
</div>
{paymentList.length!==0?

<div  className="row  ">   
<div className="col-sm-12">                                                                  
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Staff Salary Payment List</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

          {notice.isDataFetching ? <TableLoader />:
      <TableBasic data={paymentList}  table={'tbl_salary_payment'} columns={tableHeader}  />}  

            </div></div>
    
    </div>
    
    </div>
    </div>:''}


   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(SalaryPayment) 