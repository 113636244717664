import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import PageHeader from '../component/pageheader'
import {LoadEffect, TableLoader } from '../component/loader'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import axios from 'axios'
import {Alerts} from '../component/notify'
import {ServerUrl, Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
 const LoginReportStudent =()=> {
	
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });
   
     const [logList, setLogList] = useState([])
     const handleChange =(event)=>{
        let {name, value} = event.target;
        setSearch({...search, [name] : value });
    }
    const [search, setSearch]= useState({
        startDate:'',
        endDate:'',
    })
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
   
    const fetchStudentLog =()=>{
        const fd = new FormData();
        var sql = "Select usercode, username, usertype, ipaddress, browser, device, message, dateCreate, code from tbl_user_log where  usertype ='student' "

        if(search.startDate.length!==0 && search.endDate.length!==0 ){
            sql = sql + ' and dateCreate between "'+search.startDate+'" and "'+search.endDate+'"'
        }

        sql = sql + '  order by ID DESC'

     
            let url = ServerUrl+'/fetchBySql_controller'
            fd.append("jwt", Token)
            fd.append("sql", sql)
            axios.post(url, fd, config).then(result =>{
                if(result.data.length>0){
                setLogList(result.data)
                }
            }) 
    } 



    const Clearlog =()=>{
        const fd = new FormData();
        var sql = "Delete from tbl_user_log where  usertype ='student' "
     
            let url = ServerUrl+'/fetchBySql_controller'
            fd.append("jwt", Token)
            fd.append("sql", sql)
            axios.post(url, fd, config).then(result =>{
                window.location.reload()
            })
    } 
    
    const tableInHeader = [  
      {dataField: 'username',  text: 'Name',  sort: true, editable: false},
      {dataField: 'dateCreate',  text: 'Date Login',  sort: true, editable: false},
      {dataField: 'browser',  text: 'Browser',  sort: true, editable: false},
      {dataField: 'device',  text: 'Device',  sort: true, editable: false},
      {dataField: 'ipaddress',  text: 'IP',  sort: true, editable: false},
      {dataField: 'message',  text: 'Message',  sort: true, editable: false}
     ];
    

     const compare=(a,b)=>{
        let result = 0;
        if(b.order >a.order){
            result = 1
        }else if (b.order <a.order){
            result = -1
        }
        return result
        }
    
     const TableRecord=(props)=>{
         const { SearchBar } = Search;  
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '100', value: 100}, {text: '500', value: 500}, {text: '1000', value: 1000}, {text: '5000', value: 5000}, {text: '10000', value: 10000},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                             <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />

       {allowDelete==='Yes'? <div className="dt-buttons btn-group pull-right"> 
            <a href="#!" onClick={()=>Clearlog()} className="btn btn-outline-danger" > <i className="fa fa-trash"></i>Clear Log</a>         
                </div>:[]}
                
                     <BootstrapTable 
                        { ...props.baseProps }
                        striped
                    hover
                    condensed
                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                pagination={paginationFactory(options) }
              
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      useEffect(()=>{
        setIsAuthenticated(menuLink.includes(location.pathname))
        fetchStudentLog()
       },[]);
   
        return ( <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
       <PageHeader title="Login Report"
	   				menu="System"
					   />


{/* The Page Body start here */}
<div className="page-body">
         

         
<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
                       
            <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group row">
                <div className="col-lg-4 col-md-4 col-sm-4">
                            <label className='text-bold pull-right'>Start Date</label>
					</div>
									
                    <div className="col-lg-8 col-md-8 col-sm-8">
							<div className="input-group">
						<input type="date" onChange={handleChange}  name="startDate" value={search.startDate} className='form-control '/>
							
							</div>
							</div>
					</div>
					
                    
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
               

                <div className="form-group row">
					<div className="col-lg-4 col-md-4 col-sm-4">
                    <label className='text-bold pull-right'>End Date</label>
					</div>
									
                    <div className="col-lg-8 col-md-8 col-sm-8">
							<div className="input-group">
						<input type="date" onChange={handleChange}  name="endDate" value={search.endDate} className='form-control '/>
							
							</div>
							</div>
					</div>

                </div>
            </div>

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={fetchStudentLog}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div> 


							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Student Login History</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block" >
       	 <div className="row">
          <div className="col-sm-12 table-responsive">
          {notice.isDataFetching ?<TableLoader />:
          <TableRecord data={logList}  columns={tableInHeader}  />}  
            </div>
           
    
    </div></div></div>
							</div>
	                   
                            </div>

                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 

 );
        
}

export default React.memo(LoginReportStudent) 