import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl,  Token,  API_URL, config,  sectionID, classID} from '../../component/include'
import Select from 'react-select'
import { getAge, FormatNumber, getClassField, getClassName, getResultHead} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useSelector } from 'react-redux'
import { sort } from '../../component/globalFunction'

const ViewMidResult =()=> {

    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [examtype, setExamtype]=useState([])
    
    const [classTeacher, setClassTeacher] = useState([]);
    const [settings, setSettings]=useState([])
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo[0]);

const [remarkResults, setRemarkResults] = useState([])
const staff  = useSelector(state => state.rootReducer.staff);
 const [student, setStudent] = useState([]);
 const [studentList, setStudentList] = useState([]);
const [sqlQuery, setSqlQuery] = useState([]);  
const [remark, setRemark]=useState([])
const [grade, setGrade] = useState([])
const [grademark, setGrademark]=useState([])
    const [scores, setScores]=useState([])

    
    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        student:[],
        code:'',
        teacherRemark:'',
        teacherName:'',
        headRemark:'',
        headName:'',
        headofSchool:'',
        resultHead:'Secondary',
        obtained:0,
        average:0
    })

    const [signature, setSignature] = useState({});

    const [disabledResult, setDisabledResult]=useState([])
    const [attendance, setAttendance]=useState({
        P:0,
        A:0
    })
  

    const handleSearch = event =>{
        event.preventDefault(); 
        
        let errors = {};   
         
        if(result.examtype.length===0){
            errors.examtype ='Please select exam type';
        } 
        if(result.orderBy.length===0){
            errors.orderBy ='Please select order of result';
        } 

        setErrors(errors)

        if(Object.keys(errors).length === 0){
            fetchResult()
            }

    } 
        
    const fetchSettings =()=>{
        let url = API_URL+'/exam/result/session/all';
        axios.get(url,  config).then(result=>{
            if(result.data.length!==0){
                try{
                let config = JSON.parse(result.data.settings[0].settings)
                setSettings(config[0])
            }catch(e){
                   
            }
            }
            
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }
     
    var classType = String(getClassField(classID, schoolClass, 'arms'))

    

    
    const fetchRemark =()=>{
        
        if(sectionID !==undefined && sectionID !=='')
        var sql ="SELECT code, teacherRemark, studentCode, headofSchool, teacherName, headRemark, teacherName, headName, resultHead, studentCode from tbl_result_remarks where examType = '"+result.examtype.value+"' and sectionID = '"+sectionID+"'" ;

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token);
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{
    if(response.data.length!==0){
        setRemarkResults(response.data)
    }else{
        setRemarkResults('')
    }
    
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}



    const handleSelect =(option, action)=>{     
          
        setResult({...result, [action.name]:option, orderBy:[]});
        setScores('')
        
   
        setErrors({...errors, [action.name]:''})
            fetchStudentList(option.value)
            fetchClassSubject(option.value)
            fetchClassTeaher() 
    }



    const handleSelectType =(option, action)=>{  
        
        setResult({...result, [action.name]:option});
        setErrors({...errors, [action.name]:''})
        fetchStatistics()  
        fetchRemark()
        
    }

    const fetchStatistics =()=>{
        if(sectionID !==undefined && sectionID !=='')
        setNotice({...notice, isLoading: true}) 
         const fd = new FormData();
     fd.append("exam", result.examtype.value);
     fd.append("sectionID", sectionID);
    fd.append("sql", sqlQuery);
    let url = API_URL+'/exam/result/statistics';
    axios.post(url, fd, config).then(result=>{
       
         if(result.data.data.length!==0){
    setStudent(result.data.data)
    setRemarkResults(result.data.remarks)
        }else{
            setStudent([])
            setRemarkResults([]) 
        }  
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
        
    }) 
    }



        
    const fetchStudentList =async(exam)=>{
        setNotice({...notice, isLoading: true}) 
    let url = API_URL+'/student/disabled/'+sectionID+'/'+exam;
            await   axios.get(url,  config).then(result=>{

        if(result.data.data.length!==0){

            setStudentList(result.data.data)

            let studentList = result.data.studentList
            let std = studentList.length!==0?studentList[0].studentList:[]
            const options = std.length!==0? JSON.parse(std):[]

            if(options.length!==0){
                const rs = options.map((item)=>item.studentCode)
                setDisabledResult(rs)
            }else{
                
        setDisabledResult([])
            }

            }else{
                setStudentList([]) 
            } 
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
       
    })
    }


        const fetchClassSubject =(exam)=>{

            
            let url = API_URL+'/exam/subject_list/'+sectionID+'/'+exam;
            axios.get(url, config).then(response=>{
                if(response.data.data.length!==0){
                var subj = response.data.data;
    

                             
let sqlString = subj.map(data=>"sum(case when e.subjectCode = '"+data.code+"' and e.absent='No'  then e.firstCA else 0 end) AS '"+data.subjectName+"'")
                   


let final =   'SELECT  s.code, s.passport, s.gender, s.house, s.dateOfBirth, s.admissionNumber, s.registerNumber, s.studentName, sum(case when e.firstCA >0 and e.absent="No" then ((e.firstCA/20)*100) else 0 end) as obtained,  sum(case when e.firstCA >0 and e.absent="No" then 100 else 0 end) as obtainable, cast(sum(case when e.firstCA >0 and e.absent="No" then  ((e.firstCA/20)*100) else 0 end)/sum(case when e.firstCA >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, e.maxMark, e.absent, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and  e.examCode ="'+exam+'" and e.sectionID ="'+sectionID+'" group by e.studentCode '

            
             
            setSqlQuery(final)
               
            }else{
                setSqlQuery([])
            }
            })

        }


     
        const fetchExamType =()=>{
       
            let url = API_URL+'/exam/result/examtype';
            axios.get(url,  config).then(result=>{
            
                let examtype = result.data.examType.filter(item=>item.term !=="MOCK EXAM")
                setExamtype(examtype)
            
                let settings = result.data.grademark;
                var grad = settings[0].grade
            
            var gradeRemar = settings[0].gradeRemark
            var gradeMar = settings[0].gradeMark
            setRemark(JSON.parse(gradeRemar).sort((a, b) =>(a.remarkName > b.remarkName) ? 1 : -1))
            setGrademark(JSON.parse(gradeMar).sort((a, b) =>(a.classtype > b.classtype) ? 1 : -1))
            setGrade(JSON.parse(grad).sort(sort))
            
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            })
            }

        
        
const getRemark =(code)=>{
    const ans = remark.filter(item=>item.code===code)
    return ans[0].remarkName
    }

const getGrade =(score, field)=>{
    const ans = grademark.filter(itm=>Number(score) >= Number(itm.minimum) && Number(score) <= Number(itm.maximum));
    const remark = ans.length!==0? getRemark(ans[0].gradeMark):''
    const grade = ans.length!==0? getGradeScore(ans[0].gradeCode):''
return field ==='grade'?String(grade):String(remark)
}



        const fetchResult =()=>{
            setNotice({...notice, isLoading: true}) 
           
            let url = API_URL+'/exam/mid_term_result/'+sectionID+'/'+result.examtype.value;
            axios.get(url,  config).then(response=>{
    
                 if(response.data.data.length!==0){
                    var rs = response.data.data;
                    var obtained = 0;
                    var total = rs.filter(item=>Number(item.firstCA)>0)
        var list = [];
        for (var i = 0; i< rs.length;  i++) {
    
            
            var sc = (Number(rs[i].firstCA)/20)*100
               obtained+=sc;
    
            list.push(
    
                {  
                    
    
                    abbreviation:rs[i].abbreviation,
                    absent:rs[i].absent,
                    comment:rs[i].comment,
                    exam:rs[i].exam,
                    position:rs[i].position,
                    
                    studentCode:rs[i].studentCode,
                    totalAverage:FormatNumber((Number(rs[i].totalAverage)/20)*100),
                    firstCA:FormatNumber((Number(rs[i].firstCA)/20)*100),
                    grade:getGrade(sc, 'grade'),
                    remark:getGrade(sc, 'remark'),
                    total:FormatNumber(sc),
                    subjectName:rs[i].subjectName,
                    teacher:rs[i].teacher,
    
                });
                }
            
            
            setScores(list)
           
       
        window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
            }else{
                setScores('')
                setResult({...result, obtained:0})
                Alerts('Info!', 'info', 'There are no schedule found for the selected exam')
            }  
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
            
        }) 
        }


        const getGradeScore =(code)=>{
            const result = grade&&grade.filter(item=>item.code===code)
            return result.length!==0?result[0].gradeName:''
            }
    
        
          
            const fetchClassTeaher =()=>{
                var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
                setClassTeacher(newStaff)
            }

      



            const getStaffField = (code, field) =>{   
    
                const result = staff&&staff.filter(list=>list.code===code);
                const answer = result.map((c)=>c[field] ); 
                return  String(answer);
               }



     const handleStaffName=(cell)=>{
        if(cell!=='[]' && cell!=''){
        return JSON.parse(cell).map((itm, idx)=>{
            return <span  key={idx}> {' '+getStaffField(itm, 'staffName')}</span>
        } );
    }else{
        return ''
    } 
    }


       const getScores =(code)=>{
        let scr =  [...scores];
        const rs = scr.filter(item=>item.studentCode===code)
        return rs.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1);
    
    }
    
    const getStudent =(code)=>{
        let scr =  [...student];
        const rs = scr.filter(item=>item.code===code)
        return rs[0];
    
    }
    
    
    
    
    const getData =(code)=>{
         let scr =  [...remarkResults];
        const rs = scr.filter(item=>item.studentCode===code)
        return rs[0];
    
    } 



    
  
useEffect(()=>{
    fetchSettings()
    fetchExamType()
},[]);



        return ( <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
               
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Mid Term Result">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Mid Term Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row no-print ">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Student Mid Term Result</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        <div className="row">

                       
     <section className="col-md-6">
     <div className="form-group">
          <label> Exam Type <span style={{color:'red'}}>*</span></label>
          <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

 
             </div>
     
     </section>

     <section className="col-md-6">
           <div className="form-group">
         <label> Order By <span style={{color:'red'}}>*</span></label>
         <Select  options={[
             {value:'order by s.studentName ASC', label:'Order by Name'},
                    { value: 'order by s.registerNumber ASC', label: 'Order By Register Number'},
             { value: 'order by s.admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelectType} className={errors.orderBy ? 'form-control form-control-danger' : ''} name="orderBy" value={result.orderBy}  /> 
<span style={{color:'red'}}>{errors.orderBy && errors.orderBy}</span>

            </div>
     
     </section>
				</div>	


	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search Result</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	

{scores.length!==0?studentList.map((std, index)=>
<div className="card z-depth-0" key={index}>
		<div className="card-block">
	
        <div className="row table-responsive" >

<table className="table  table-full-width ">
       <tbody>
       <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${schoolInfo.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td className="text-center" >
                        

                 
                        <h4 style={{textAlign:'center'}}><b>{schoolInfo.schoolName}</b></h4>
                        <h6 style={{textAlign:'center'}}><b>Motto: {schoolInfo.schoolMotto}</b></h6>
                        <div style={{textAlign:'center'}}>  {schoolInfo.address}  </div>
                        <div style={{textAlign:'center'}}> {schoolInfo.mobile}, {schoolInfo.altMobile}, {schoolInfo.email} </div>
                        
                        <div style={{textAlign:'center'}}>  Website: {schoolInfo.website}  </div>
                             </td>

 <td > 
    <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  />
{/* 
    <img src={std.qrcode} className="img-100" style={{ height:'100px' }}   /> */}

 </td>
                </tr>
            </tbody>
       </table>

</div> 
                    

 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>MID-TERM REPORT {result.examtype.label} </b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>

              <tr>
                <td >ADMISSION NO: <b> {std.admissionNumber} </b></td>
                    <td >NAME: <b>{std.studentName}</b></td>
                    
                    <td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>
                </tr>
                <tr>
                    
                <td>CLASS: <b>  {getClassName(classID, sectionID, schoolClass)}  </b></td>
                    <td >NUMBER IN CLASS: <b>{studentList.length}</b></td>
                    <td >GENDER: <b>{std.gender}</b></td>
                   
                </tr>
                <tr>
                <td >MARK OBTAINABLE: <b>{getStudent(std.code).obtainable}</b></td>
                    
                    <td >MARKS OBTAINED: <b>{getStudent(std.code).obtained && Number(getStudent(std.code).obtained).toFixed(0)} </b></td>
                   
                        <td >STUDENT'S AVERAGE: <b> {getStudent(std.code).average && Number(getStudent(std.code).average).toFixed(2)}  </b></td>
                    </tr>

                

            </tbody>
       </table></div>
       <div className="col-md-12 table-responsive">
        <table className="table table-striped table-bordered">
        

            <thead>
                <tr>
                    <th >Subjects</th>
                    {getResultHead(classType)==='Secondary'?  <th >Teacher</th>:''}
                    <th >1st CAT (100%)</th>
                    <th >Total</th>
                    <th >Grade</th>
                    <th >Remark</th>
                </tr>
            </thead>
            <tbody>
                
            {getScores(std.code).map((st, idx)=> st.absent!=='Yes' && Number(st.firstCA) >0?
                <tr key={idx}>
                
                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.subjectName.toUpperCase()}</td>
                    {getResultHead(classType)==='Secondary'?  <td style={{maxWidth:'120px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(st.teacher)}</td>:''}
                    <td>{st.firstCA}</td>
                    <td>{st.total}</td>
                    <td>{st.grade}</td>
                    <td>{st.remark}</td>
                
                    </tr>
                :[])} 
            </tbody> 
        </table>
 </div>	 
 <div className="col-md-12">
     <h6>*CAT Continuous Assessment Test</h6>
     <table className='table table-bordered table-responsive'>
         <tbody>
             <tr>
             <td  style={{ align:'center', valign:'center' }}  colSpan={2} rowSpan={2}><b >KEY TO GRADES </b></td>
                 <td><b>Score Range </b></td>
                 {grademark.map((data, index)=><td key={index}>{data.minimum + ' - ' + data.maximum}</td>)}

             </tr>
             <tr>
                 <td><b>Grade </b></td>
                 {grademark.map((data, index)=><td key={index}>{getGradeScore(data.gradeCode)}</td>)}
                 
             </tr>
         </tbody>
     </table>
     
<table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'5px' }}><b>Form Tutor(s) </b></td>
                 <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}</td>

             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).teacherRemark} </td>
                 
             </tr>
         </tbody>
     </table>
     {getData(std.code).resultHead==='Nursery' ||getData(std.code).resultHead==='Pre-Nursery'?  <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Nursery </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofNursery, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark}

                </td>
                <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings&&settings.headofNursery, 'signature')}`}   alt={settings&& getStaffField(settings.headofNursery, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>: getData(std.code).resultHead==='Primary'?
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Primary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>:

     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Secondary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>}
<table className='table table-bordered'>
 <tbody>
     <tr>
         <td style={{maxWidth:'10px' }}><b>Head of School </b></td>
         <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofSchool, 'staffName')}</td>
         
         <td style={{maxWidth:'5px' }}> 
         <img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings&&settings.headofSchool, 'signature')}`}   alt={settings&& getStaffField(settings.headofSchool, 'staffName')} /></td>
     </tr>
 </tbody>
</table>
 </div>
 </div> 
 
 <div className="row no-print">
        <div className="col-md-12 pull-right">
        <form method='POST' action={ServerUrl+'/printout/student_midterm_result.php?token='+userToken} target='_blank'>
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                <input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                <input type='hidden' name='classID' value ={classID} />
                <input type='hidden' name='sectionID' value ={sectionID} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />

                <input type='hidden' name='studentList' value ={JSON.stringify(studentList, null, 2)} />
                <input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />
                <input type='hidden' name='present' value ={attendance.P} />
                 

                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='imageUrl' value ={`${
ImagesUrl}/signature/${signature}`} />

        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
 {disabledResult&&disabledResult.includes(std.code)? 
<div style={{ 

height:'100%',
backgroundColor:'black',
width:'100%',
alignItems:'center',
justifyContent:'center',
flexDirection:'column',
display:'flex',
flex:1,
position:'absolute',
padding:'20px',
opacity:0.98


 }}>


<h3 style={{ color:'white',  }}> Result not available at the momemt, please contact the Account's Department</h3>

</div>
:''}  
        </div>):[]}



    

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ViewMidResult) 