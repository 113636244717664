import React, {useState, useEffect, useCallback} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, staffCode, allowDelete, allowEdit, menuLink, API_URL} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, longDate, sort, getRank, getCAPoint, getResultHead} from '../component/globalFunction'
import { useSelector } from 'react-redux'

import { useLocation } from 'react-router-dom'
import Error403 from '../settings/error403'
 const ResultEntry =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    const [csvFile, setCsvFile]=useState({
        studentCsv:'',
        fileName:'Please select .csv files (allowed file size 1Mb)'
    })


    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        subject:[],
        orderBy:{value:'order by s.studentName ASC', label:'order by Name'}
    })


    const steps = [{title: 'Search Exam'}, {title: 'Enter Score'}]
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
   
    const [examtype, setExamtype]=useState([])
    const [subject, setSubject]= useState([]) 
    const [studentList, setStudentList]=useState([])
    const [errors, setErrors] = useState({});
    const [grade, setGrade] = useState([])
    const [grademark, setGrademark]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [remark, setRemark]=useState([])
const [activeStep, setActiveStep] = useState(1);
const handleOnClickStepper = (step) => {
        setActiveStep(step); 
    }

    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep])



    const fetchExamType =()=>{
       
        let url = API_URL+'/exam/result/examtype';
        axios.get(url,  config).then(result=>{
        
            let examtyp = result.data.examType.filter(item=>item.term !=="MOCK EXAM" && item.publish ==='No')
            setExamtype(examtyp)
        
            let settings = result.data.grademark;
            var grad = settings[0].grade
        
        var gradeRemar = settings[0].gradeRemark
        var gradeMar = settings[0].gradeMark
        setRemark(JSON.parse(gradeRemar).sort((a, b) =>(a.remarkName > b.remarkName) ? 1 : -1))
        setGrademark(JSON.parse(gradeMar).sort((a, b) =>(a.classtype > b.classtype) ? 1 : -1))
        setGrade(JSON.parse(grad).sort(sort))
        
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }


  
        const fetchSubject =(type, section)=>{
            setNotice({...notice, isLoading: true}) 
        let url = API_URL+'/subject/exam/'+type+'/'+section;
        axios.get(url, config).then(result=>{
            if(Array.isArray(result.data.data)){
                setSubject(result.data.data)
            }else{
                setSubject([]) 
            }
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
        })
        }











const fetchStudentList =()=>{
    
    let errors = {};   
   
    if(result.studentClass.length===0){
        errors.studentClass ='Please select class';
    }   
    if(result.examtype.length===0){
        errors.examtype ='Please select exam type';
    } 
    if(result.subject.length===0){
        errors.subject ='Please select subject';
    } 
     
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

        setNotice({...notice, isLoading: true}) 

const fd = new FormData();

fd.append("examtype", result.examtype.value);
fd.append('subject', result.subject.value)
fd.append("orderBy", result.orderBy.value);
fd.append('studentClass', result.studentClass.value)



let url = API_URL+'/exam/result/student';
axios.post(url, fd, config).then(result=>{
    if(result.data.data.length!== 0 && Array.isArray(result.data.data)){
      
        //staffCode
     const stf = result.data.data[0].teacher;

 if(stf.includes(staffCode)){
    setStudentList(result.data.data)
    setActiveStep(2)
}else{
    setStudentList([])
    Alerts('Error!', 'danger', 'Only subject teacher can record or modify exam scores ');
} 

    }
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
    }
}





function submit(){
    
    setNotice({...notice, 
        isLoading: true})  

   
        const fd = new FormData(); 
       fd.append('studentList', JSON.stringify(studentList, null, 2));
        let url = API_URL+'/exam/result/update'; 
              
            axios.post(url, fd, config)
            .then(response =>{
                if(response.data.status ===200){
                    Alerts('Updated!', 'default', response.data.message)
                        } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data.message))
                        }   
                })
                .catch((error)=>{
                    Alerts('Error!', 'danger', error.message)
                  }).finally(()=>{
                    
                      setNotice({...notice, 
                          isLoading: false })
                      
                    setIsSubmitting(false);
                      setActiveStep(1)
                  })   
      }



const handleSelect = (option, action)=>{
    setResult({...result, [action.name]: option });
   setErrors({...errors, [action.name]:''})
   if(action.name==='studentClass'){
    fetchSubject(result.examtype.value, option.value)
   }else if(action.name==='examtype'){
   fetchSubject(option.value, result.studentClass.value)
   }
   setStudentList([])
  
}





const handleChangeScore =(event, index)=>{
    const student =JSON.parse(JSON.stringify(studentList))
    const {name, value} = event.target
    student[index][name] = value;
    setStudentList(student); 
       } 


const handleChangeGrade =(event, index, tot)=>{
    const student =JSON.parse(JSON.stringify(studentList))
    const currentResult = student.filter((item, id)=>id===index)
   
    let {name, value} = event.target
    value = value.replace(/[^0-9]/g, '');
    var firstCA  = parseFloat(currentResult[0].firstCA)
    var secondCA = parseFloat(currentResult[0].secondCA)

    var thirdCA = parseFloat(currentResult[0].thirdCA)
    var fourthCA = parseFloat(currentResult[0].fourthCA)

    var fifthCA = parseFloat(currentResult[0].fifthCA)
    var sixthCA = parseFloat(currentResult[0].sixthCA)

    var exam = parseFloat(currentResult[0].exam)
    var total = parseFloat(currentResult[0].total)

    switch(name) {
        case 'firstCA':
            if(value<=tot){
          total =  Number(value) + secondCA + thirdCA + fourthCA + exam + fifthCA + sixthCA
            }else{
            value =0
        }
          break;
          case 'secondCA':
            if(value<=tot){
          total =  Number(value) + firstCA + thirdCA + fourthCA + exam + fifthCA + sixthCA
            }else{
            value =0}
          break;
            case 'thirdCA':
            if(value<=tot){
            total =  Number(value) + firstCA + secondCA + fourthCA + exam  + fifthCA + sixthCA
        }else{
            value =0
        }
        break;
        case 'fourthCA':
            if(value<=tot){
            total =  Number(value) + firstCA + secondCA + thirdCA + exam  + fifthCA + sixthCA
        }else{
            value =0
        }
        break;
        case 'fifthCA':
            if(value<=tot){
          total =  Number(value) + firstCA + thirdCA + fourthCA + exam  + secondCA + sixthCA
            }else{
            value =0}
          break;
          case 'sixthCA':

            if(value<=tot){
          total =  Number(value) + firstCA + thirdCA + fourthCA + exam + secondCA + sixthCA
            }else{
            value =0}
          break;
                  case 'exam':
                    if(value<=tot){
                    total =  Number(value) + firstCA + secondCA + thirdCA + fourthCA + fifthCA + sixthCA
                }else{
                    value =0}
                      break;
                 default:
      } 


   
   

    student[index][name] = value;
    student[index].total = total
    student[index].grade = getGrade(total, 'grade')
    student[index].remark = getGrade(total, 'remark')

  setStudentList(student); 
       } 

  
const getGradeScore =(code)=>{
const ans = grade.filter(item=>item.code===code)
return ans[0].gradeName
}

const getRemark =(code)=>{
    const ans = remark.filter(item=>item.code===code)
    return ans[0].remarkName
    }

const roundNum =(num)=> (Math.round(parseFloat(num)*100)/100)
const getGrade =(score, field)=>{
   
    const ans = grademark.filter(itm=>itm.classtype===result.studentClass.classtype&& roundNum(score) >= roundNum(itm.minimum) && roundNum(score) <= roundNum(itm.maximum));
    const remark = ans.length!==0? getRemark(ans[0].gradeMark):''
    const grade = ans.length!==0? getGradeScore(ans[0].gradeCode):''
   

return field ==='grade'?String(grade):String(remark)
}

 
const handleSubmit = event =>{
    event.preventDefault();    
    
    let errors = {};   
    if(studentList.length===0){
        errors.studentClass ='There are no student in the selected class';
    } 
    if(result.studentClass.length===0){
        errors.studentClass ='Please select class';
    }   
    if(result.examtype.length===0){
        errors.examtype ='Please select exam type';
    } 
    if(result.subject.length===0){
        errors.subject ='Please select subject';
    } 
     
    
    setErrors(errors)

    if(Object.keys(errors).length === 0){

       submit()
    }


}

   
    





const handleReset=()=>{
    setResult({ 
        studentClass:[],
        examtype:[],
        subject:[],
        orderBy:{value:'order by s.studentName ASC', label:'order by Name'}
        })

        setStudentList([])
}


const  handleSubmitFile=(event)=>{ 
    event.preventDefault(); 

    if(!csvFile.studentCsv){
        //errors.studentCsv ='You have not choose any file to upload';
        Alerts('Error!', 'danger', 'You have not choose any file to upload')
    }else{
    
    const fd = new FormData();
        fd.append('jwt', Token); 
        fd.append('studentCsv', csvFile.studentCsv);

     setNotice({...notice,  isLoading: true}) 

       let url = ServerUrl+'/update_controller/importresult'

      axios.post(url, fd, config).then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{ 
            setNotice({...notice,  isLoading: false}) 
     setCsvFile({...csvFile, studentCsv:'', fileName:'Please select .csv files (allowed file size 800KB)'}); 
     document.getElementById('studentCsv').value=''
        }) 
} 

}

const  handleFile = function(fieldName){
    return function(newValue){  
       
        setErrors({...errors, [fieldName]:''})
   readURL(newValue);
   }
   
} 


const readURL = (input)=>{
   
    let displayMessage = '';
    const PreviewImage = input.target.name;
        var fileName = document.getElementById(PreviewImage).value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    

    if (extFile==="csv" || extFile==="CSV"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 1) {
        displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 1 Mb';
       document.getElementById(input.target.name).value = '';
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
               const value = input.target.files[0]
        setCsvFile({...csvFile, studentCsv:value, fileName:value.name})
       
        }
    }
}else{

    displayMessage = 'Only csv|CSV  files are allowed with maximum of 1Mb'
    document.getElementById(input.target.name).value = '';
    setCsvFile({...csvFile, studentCsv:'', fileName:'Please select .csv files (allowed file size 1Mb)'})
}  

if(displayMessage.length > 0){
    Alerts('Error!', 'danger', displayMessage)
  }

} 




   useEffect(()=>{
    //update every 4 minutes
    const interval =   setInterval(()=>{
        
        if(studentList.length!==0){
            const fd = new FormData(); 
       fd.append('studentList', JSON.stringify(studentList, null, 2));
        let url = API_URL+'/exam/result/update';
              
            axios.post(url, fd, config)
            .then(response =>{
                if(response.data.status ===200){
                    Alerts('Updated!', 'default', 'You information has been saved')
                        } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
                        }   
                })
                .catch((error)=>{
                    Alerts('Error!', 'danger', error.message)
                  }).finally(()=>{
                   
                  })
    
                }
    }, 4 * 60 * 1000);
    return () => {
        if(interval){
          clearInterval(interval)
        }
      }
   },[])  




    useEffect(()=>{
        
        fetchExamType()
    },[]);
  

        return ( 
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 

        <PageHeader title="Result Entry">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Result Entry</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">



               


    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Exam Result Entry</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '1px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="examType">
		  
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

      
   <div className="card-block">
      
  <div className="row">
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Exam Type <span style={{color:'red'}}>*</span></label><a href="/exam/result_setup" className="pull-right" >Add more exam</a>
         <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

            </div>
     
     </section>
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label><a href="/setup/school_classes" className="pull-right" >Add more classes</a>
        <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
     
     </section>
     </div>
     <div className="row">
     <section className="col-md-6">
           <div className="form-group">
         <label> Subject<span style={{color:'red'}}>*</span></label><a href="/setup/class_subject" className="pull-right" >Add subject</a>
         <Select options={
                            subject&&subject.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.subjectName, abr:list.abbreviation }
                             })} 
                             getOptionLabel={option =>`${option.label} - ${option.abr}`}
onChange={handleSelect} className={errors.subject ? 'form-control form-control-danger' : ''} name="subject" value={result.subject}  /> <span style={{color:'red'}}>{errors.subject && errors.subject}</span>	</div>
     
     </section>
     
     <section className="col-md-6">
           <div className="form-group">
         <label> Order</label>
         <Select  options={[
             {value:'order by s.studentName ASC', label:'order by Name'},
                    { value: 'order by s.registerNumber ASC', label: 'Order By Register Number'},
             { value: 'order by s.admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelect}  name="orderBy" value={result.orderBy}  /> 

            </div>
     
     </section>
     
     </div>

     <div  className="row"> 
     
     <section className="col-md-12"> 


     <footer className="pull-right">
	<button type="button" onClick={handleReset} className="btn btn-inverse "><i className="fa fa-refresh"></i> Reset</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={fetchStudentList} className="btn btn-primary "><i className="fa fa-graduation-cap"></i> Display Student</button>
    </footer>						
     </section>

     </div> 
    </div>
                               
       </div>	     
       
       	</div>
       
       </div>	
			
       </div>
    
</div> :
<div className="row setup-content" id="extras">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">

   <div className="row">
   <div className='col-md-12 '>
           <div className='pull-right'>
               
           <b>{result.subject.length!==0&&result.studentClass.length!==0&&result.studentClass.label.toUpperCase()+' -'+result.subject.label.toUpperCase()}</b>

           </div></div>
   <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                <th>SN</th>
                    <th>Student Details</th>
                    

                    

                    {getResultHead(result.studentClass.classtype)==='Pre-Nursery'?<>
                    

                    <th>1<sup>st</sup> CAT (10)</th>
                    <th>2<sup>nd</sup> CAT (30)</th>
                    <th>Classwork (10)</th>
                    <th>Assignment (10)</th>
                    <th>Exam (40)</th>
                    </>:

                    getResultHead(result.studentClass.classtype)==='Secondary'?<>

                    <th>1<sup>st</sup> CA ({result.studentClass.length!==0?getCAPoint(result.studentClass.classtype).ca:15})</th>
                    <th>2<sup>nd</sup> CA ({result.studentClass.length!==0?getCAPoint(result.studentClass.classtype).ca:15})</th>
                    <th>3<sup>rd</sup> CA </th>
                    <th>Exam ({result.studentClass.length!==0?getCAPoint(result.studentClass.classtype).exam:60})</th>
                    
                    </>:
                    
                    
                    <><th>Homework (10)</th>
                    <th>Participation (10)</th>

                    <th>1<sup>st</sup> CA (20)</th>

                    <th>Projects(5)</th>
                    <th>Quiz (5)</th>
                    <th>2<sup>nd</sup> CA (10)</th>
                   
                    <th>Exam ({result.studentClass.length!==0?getCAPoint(result.studentClass.classtype).exam:40})</th>
                    </>
                    }
                    <th>Total (100)</th>
                    <th>Grade</th>
                    <th>Remark</th> 
                    
                    <th>Position</th>
                    <th>Absent</th>
                </tr>
            </thead>
            <tbody>{studentList.length!==0?studentList.map((st, idx)=> 
                <tr key={idx}>
                    
                    <td>{idx+1}</td>

                    <td style={{maxWidth:'300px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}><b>{st.admissionNumber} - {st.studentName}</b> {st.termination==='Yes'?<div><br/><span className="pcoded-badge label label-danger">Terminated on {longDate(st.termination_date)} {st.termination_remark}</span></div>:''} 
                    
                    
                    </td>
                   
                    {getResultHead(result.studentClass.classtype)==='Pre-Nursery'?<>
                    
                    <td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 10)} className="form-control w-30" id={idx+'f'} name='firstCA' value={st.firstCA}  onChange={(e)=>handleChangeGrade(e, idx, 10)} /></td>

<td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 30)} className="form-control " id={idx+'s'} name='secondCA' value={st.secondCA}  onChange={(e)=>handleChangeGrade(e, idx, 30)} /></td>




<td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 10)} className="form-control" id={idx+'t'} name='thirdCA' value={st.thirdCA} onChange={(e)=>handleChangeGrade(e, idx, 10)} /></td>




<td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 10)} className="form-control" id={idx+'fo'} name='fourthCA' value={st.fourthCA}  onChange={(e)=>handleChangeGrade(e, idx, 10)} /></td>

<td><input type="text" onFocus={(e)=>e.target.select()}  onBlur={(e)=>handleChangeGrade(e, idx, 60)} className="form-control" pattern='[0-9]' min={0} step={1} id={idx+'e'}name='exam' value={st.exam}  onChange={(e)=>handleChangeGrade(e, idx, 40)} /></td>
                    </>:
                    getResultHead(result.studentClass.classtype)==='Secondary'?<>
                    
                    
                    <td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 15)} className="form-control" id={idx+'f'} name='firstCA' style={{width:'60px'}} value={st.firstCA}  onChange={(e)=>handleChangeGrade(e, idx, 15)} /></td>

                    <td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 15)} className="form-control" id={idx+'s'} name='secondCA' value={st.secondCA} style={{width:'60px'}} onChange={(e)=>handleChangeGrade(e, idx, 15)} /></td>


                    <td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 10)} className="form-control" id={idx+'t'} name='thirdCA' style={{width:'60px'}} value={st.thirdCA}  onChange={(e)=>handleChangeGrade(e, idx, 10)} /></td>

                    <td><input type="text" onFocus={(e)=>e.target.select()}  onBlur={(e)=>handleChangeGrade(e, idx, 60)} className="form-control" pattern='[0-9]' min={0} step={1} id={idx+'e'} name='exam' value={st.exam}  onChange={(e)=>handleChangeGrade(e, idx, 60)} /></td>

                    </>
                    
                    
                    
                    :<> 
                    


                    <td>
                        
                        <input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 10)} className="form-control" id={idx+'f'} name='firstCA' value={st.firstCA}  onChange={(e)=>handleChangeGrade(e, idx, 10)} /></td>

<td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 10)} className="form-control" id={idx+'se'} name='secondCA' value={st.secondCA}  onChange={(e)=>handleChangeGrade(e, idx, 10)} /></td>




<td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 20)} className="form-control" id={idx+'t'} name='thirdCA' value={st.thirdCA}  onChange={(e)=>handleChangeGrade(e, idx, 20)} /></td>




<td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 5)} className="form-control" id={idx+'fo'} name='fourthCA' value={st.fourthCA}  onChange={(e)=>handleChangeGrade(e, idx, 5)} /></td>



<td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 5)} className="form-control" id={idx+'fi'} name='fifthCA' value={st.fifthCA}  onChange={(e)=>handleChangeGrade(e, idx, 5)} /></td>



<td><input type="text" onFocus={(e)=>e.target.select()}  pattern='[0-9]' min={0}  onBlur={(e)=>handleChangeGrade(e, idx, 10)} className="form-control" id={idx+'si'} name='sixthCA' value={st.sixthCA}  onChange={(e)=>handleChangeGrade(e, idx, 10)} /></td>

<td><input type="text" onFocus={(e)=>e.target.select()}  onBlur={(e)=>handleChangeGrade(e, idx, 40)} className="form-control" pattern='[0-9]' min={0} step={1} id={idx+'e'} name='exam' value={st.exam}  onChange={(e)=>handleChangeGrade(e, idx, 40)} /></td>


                    </>}
                    
                    

                    <td>{st.total}</td>
                    <td><b>{st.grade}</b></td>
                    <td style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}><b>{st.remark}</b></td>
                    
                    
                    <td>{st.rank}<sup>{getRank(st.rank)}</sup></td>
               
                    <td>

                    <select name="absent" value={st.absent} style={{width:'70px'}} className="form-control" onChange={(e)=>handleChangeScore(e, idx)}   >
                    <option  value='No'>No</option>
                    <option  value='Yes'>Yes</option>
					</select>

                    </td>

                </tr>):[]}
            </tbody> 
        </table>
 </div>	 </div>   
<div style={{color:'red'}}>{errors.subject && errors.subject}</div>
<div style={{color:'red'}}>{errors.examtype && errors.examtype}</div>
<div style={{color:'red'}}>{errors.studentClass && errors.studentClass}</div>
       </div>			
       </div>       
       </div>

</div>}


     <button onClick={activeStep === steps.length ? handleSubmit: handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Update Result </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }

                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>


                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ResultEntry) 